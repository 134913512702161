import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Box } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import ReactQuill from "react-quill";
import Loop from "src/components/loop";
import useFaqs from "./hooks/use-faqs";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  borderRadius: "10px",
  marginBottom: "5px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", margin: "15px 15px 26px 10px" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  borderRadius: "10px",
  marginBottom: "5px",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    borderRadius: "10px",
    marginBottom: "5px",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  borderRadius: "10px",
  marginBottom: "5px",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const data = useFaqs();
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Frequently Asked Question
      </Typography>
      <Loop
        list={data}
        render={({ id, question, answer }) => {
          return (
            <Accordion
              key={id}
              expanded={expanded === id}
              onChange={handleChange(id)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ backgroundColor: "#dbe5f9" }}
              >
                <Typography>{question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ReactQuill
                  value={answer}
                  readOnly
                  theme="bubble"
                  modules={{
                    toolbar: false,
                  }}
                />
                {/* <Typography>{answer}</Typography> */}
              </AccordionDetails>
            </Accordion>
          );
        }}
      />
    </Box>
  );
}
