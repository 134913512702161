import React, { useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Policy from "src/components/root_page/pages/pdf-datas/policy.jsx";
import TermsConditions from "src/images/Privacy_Terms.pdf";
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <div class="bannerFooter">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <Policy />
              {/* <ul class="bannerfooternav">
                <Policy />

                <li>
                  <a href={TermsConditions} target="_blank">
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a href={TermsConditions} target="_blank">
                    Disclosure
                  </a>
                </li>
                <li>
                  <a href={TermsConditions} target="_blank">
                    Cookies
                  </a>
                </li>
              </ul> */}
            </div>
            <div class="col-md-5">
              <p class="footer-address">
                S.A, Los Verde, 101 Avenida Norte #517, San Salvador, El
                Salvador
              </p>
            </div>
            <div class="col-md-3">
              <p class="footer-copyright">Copyright {currentYear} @ BITZSTOX</p>
            </div>
          </div>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Accept Cookies"
        declineButtonText="Decline Cookies"
        enableDeclineButton
        flipButtons
        cookieName="BITZSTOX"
        buttonClass="custom-button"
        style={{ background: "#2B373B", fontFamily: "Montserrat, sans-serif" }}
        buttonStyle={{
          color: "#4e503b",
          fontSize: "13px",
        }}
        expires={150}
        overlay
      >
        <p style={{ fontWeight: 500 }}> This website uses cookies</p>
        <p style={{ fontSize: "13px" }}>
          We use cookies to personalize content, to provide social media
          features, and to analyze our traffic. We also share information about
          your use of our site with our social media, advertising, and analytics
          partners who may combine it with other information that you've
          provided to them or that they've collected from your use of their
          services. For more information view our cookie policy.
        </p>
        <div
          style={{
            marginTop: "0.5rem",
            display: "flex",
            alignItems: "center",
            color: "#59b0f4",
          }}
        >
          <input type="checkbox" id="sell" name="sell" value="sell" />
          <label for="sell" style={{ fontSize: "13px", marginLeft: "5px" }}>
            I Do Not Sell My Personal Information
          </label>
          <br></br>
        </div>
      </CookieConsent>
    </div>
  );
};

export default Footer;
