import { Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import Footer from "src/components/root_page/layout/footer.js";
import Header from "src/components/root_page/layout/header.js";
import Alejandro from "src/images/ourteam/alejandro-5.png";
import Elonzo from "src/images/ourteam/elonzo-13.png";
import Emiliano from "src/images/ourteam/emiliano-1.png";
import Gael from "src/images/ourteam/gael-8.png";
import Hazel from "src/images/ourteam/hazel-6.png";
import Kaela from "src/images/ourteam/kaela-10.png";
import Mateo from "src/images/ourteam/mateo-7.png";
import Mauricio from "src/images/ourteam/mauricio-2.png";
import Pablo from "src/images/ourteam/pablo-12.png";
import Raquel from "src/images/ourteam/raquel-9.png";
import Romina from "src/images/ourteam/romina-14.png";
import Tadeo from "src/images/ourteam/tadeo-3.png";
import Valeria from "src/images/ourteam/valeria-4.png";
import Yamileth from "src/images/ourteam/yamileth-11.png";

const Index = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="MobileOptimized" content="width" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="title" content="BITZSTOX | The future of Finance" />
        <title>BITZSTOX | The future of Finance</title>
        <link
          rel="icon"
          href={localStorage.getItem("favicon") || "public/favicon/favicon.ico"}
          type="image/x-icon"
        />
        <meta
          name="keywords"
          content="If crypto succeeds, it's not because it empowers better people. It's because it empowers better institutions."
        />
        <meta name="author" content="BITZSTOX" />
        <meta
          name="description"
          content="If crypto succeeds, it's not because it empowers better people. It's because it empowers better institutions."
        />
        <link href="assets/css/style.css" rel="stylesheet" type="text/css" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <body
        className="banner"
        style={{ fontFamily: "'Montserrat', sans-serif" }}
      >
        <div>
          <Header />
          {/* <!-- *************nav end **************** --> */}
          <section class="teamSection">
            <div class="container">
              <div class="row">
                <div
                  className="col-md-12 teamMainDiv"
                  style={{ marginTop: "1rem" }}
                >
                  <h4 className="teamHead">CEO & Founder</h4>
                </div>
                <div class="col-md-12 teamMainDiv">
                  <div className="teamList">
                    <img src={Emiliano} className="teamImage" />
                    <h5 className="teamSubtile">Emiliano Hernandez</h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  className="col-md-12 teamMainDiv"
                  style={{ marginTop: "1rem" }}
                >
                  <h4 className="teamHead">Co-Founder</h4>
                </div>
                <div class="col-md-12 teamMainDiv">
                  <div className="teamList">
                    <img src={Mauricio} className="teamImage" />
                    <h5 className="teamSubtile">Mauricio Perez</h5>
                  </div>
                  <div className="teamList">
                    <img src={Tadeo} className="teamImage" />
                    <h5 className="teamSubtile">Tadeo Blance</h5>
                  </div>
                  <div className="teamList">
                    <img src={Valeria} className="teamImage" />
                    <h5 className="teamSubtile">Valeria Rodriguez</h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  className="col-md-12 teamMainDiv"
                  style={{ marginTop: "2rem" }}
                >
                  <h4 className="teamHead">
                    Technical Support & Operations Team
                  </h4>
                </div>
                <div
                  class="teamMainDiv"
                  style={{ width: "1000px", margin: "auto" }}
                >
                  <div className="teamList">
                    <img src={Alejandro} className="teamImage" />
                    <h5 className="teamSubtile">Alejandro Gomez</h5>
                  </div>
                  <div className="teamList">
                    <img src={Hazel} className="teamImage" />
                    <h5 className="teamSubtile">Hazel Castillo</h5>
                  </div>
                  <div className="teamList">
                    <img src={Mateo} className="teamImage" />
                    <h5 className="teamSubtile">Mateo Sanchez</h5>
                  </div>
                  <div className="teamList">
                    <img src={Gael} className="teamImage" />
                    <h5 className="teamSubtile">Gael Fernandez</h5>
                  </div>
                  <div className="teamList">
                    <img src={Raquel} className="teamImage" />
                    <h5 className="teamSubtile">Raquel Nunez</h5>
                  </div>
                  <div className="teamList">
                    <img src={Kaela} className="teamImage" />
                    <h5 className="teamSubtile">Kaela De Leon</h5>
                  </div>
                  <div className="teamList">
                    <img src={Yamileth} className="teamImage" />
                    <h5 className="teamSubtile">Yamileth Moreno </h5>
                  </div>
                  <div className="teamList">
                    <img src={Pablo} className="teamImage" />
                    <h5 className="teamSubtile">Pablo Reyes</h5>
                  </div>
                  <div className="teamList">
                    <img src={Elonzo} className="teamImage" />
                    <h5 className="teamSubtile">Elonzo Vasquez</h5>
                  </div>
                  <div className="teamList">
                    <img src={Romina} className="teamImage" />
                    <h5 className="teamSubtile">Romina Martin</h5>
                  </div>
                </div>
                {/* <div
                  class="col-md-12 teamMainDiv"
                  style={{ marginTop: "1.5rem" }}
                >
                  <div style={{ textAlign: "center",width: "180px" }}>
                    <img src={Kaela} className="teamImage" />
                    <h5 className="teamSubtile">Kaela De Leon</h5>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <img src={Yamileth} className="teamImage" />
                    <h5 className="teamSubtile">Yamileth Moreno </h5>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <img src={Pablo} className="teamImage" />
                    <h5 className="teamSubtile">Pablo Reyes</h5>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <img src={Elonzo} className="teamImage" />
                    <h5 className="teamSubtile">Elonzo Vasquez</h5>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <img src={Romina} className="teamImage" />
                    <h5 className="teamSubtile">Romina Martin</h5>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </body>
    </>
  );
};

export default Index;
