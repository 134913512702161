import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Binary = Loadable(lazy(() => import("src/pages/genealogy/binary/index")));
const Sponsor = Loadable(
  lazy(() => import("src/pages/genealogy/sponsor/index"))
);
const Tree = Loadable(lazy(() => import("src/pages/genealogy/tree/index")));

const Pool100 = Loadable(
  lazy(() => import("src/pages/genealogy/Pool100/index"))
);
const Pool250 = Loadable(
  lazy(() => import("src/pages/genealogy/Pool250/index"))
);
const Pool500 = Loadable(
  lazy(() => import("src/pages/genealogy/Pool500/index"))
);
const Pool1000 = Loadable(
  lazy(() => import("src/pages/genealogy/Pool1000/index"))
);

const Rebirth100 = Loadable(
  lazy(() => import("src/pages/genealogy/Rebirth100/index"))
);
const Rebirth250 = Loadable(
  lazy(() => import("src/pages/genealogy/Rebirth250/index"))
);

const Rebirth500 = Loadable(
  lazy(() => import("src/pages/genealogy/Rebirth500/index"))
);

const Rebirth1000 = Loadable(
  lazy(() => import("src/pages/genealogy/Rebirth1000/index"))
);

const genealogy = [
  {
    path: "genealogy",
    children: [
      { element: <Navigate to="binary" />, index: true },
      { path: "binary", element: <Binary /> },
      { path: "pool100", element: <Pool100 /> },
      { path: "pool250", element: <Pool250 /> },
      { path: "pool500", element: <Pool500 /> },
      { path: "pool1000", element: <Pool1000 /> },
      { path: "rebirth100", element: <Rebirth100 /> },
      { path: "rebirth250", element: <Rebirth250 /> },
      { path: "rebirth500", element: <Rebirth500 /> },
      { path: "rebirth1000", element: <Rebirth1000 /> },
      { path: "sponsor", element: <Sponsor /> },
      { path: "tree", element: <Tree /> },
    ],
  },
];

export default genealogy;
