import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axiosInstance from "src/utils/axios";

import * as Yup from "yup";

const Validator = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Passcode must be at least 8 characters")
    .required("New Passcode is required"),
  repassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passcode must match"
  ),
});

const useDirectorsDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [detailId, setDetailId] = useState(null);
  const defaultValues = {
    username: "",
    email: "",
    password: "",
    repassword: "",
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(Validator),
  });

  const fetchData = async () => {
    try {
      const { data } = await axiosInstance("/api/admin/director-update-get");
      const { status, data1: details } = data;

      if (status) {
        const { email, username, id } = details;
        setDetailId(id);
        methods.reset({
          email,
          username,
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return { methods, detailId };
};

export default useDirectorsDetails;
