import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { capitalCase } from "change-case";
import Scrollbar from "src/components/Scrollbar";
import useLocales from "src/hooks/useLocales";
// import useEwallet from "../hooks/useEwallet";
// import Filter from "./filterCard";
import EmptyContent from "src/components/EmptyContent";
import ParseDate from "src/components/parse-date";
import Filter from "./filterCard";
import usePayout from "./useFetchPayout";

const DataList = () => {
  const { translate } = useLocales();
  const { data, fetchData, rowStart, setFilter } = usePayout();

  return (
    <>
      <Card sx={{ mt: 2, p: 3 }}>
        <Filter setFilter={setFilter} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate("userFinancial.requestPayout.no")}
                  </TableCell>

                  <TableCell> {translate("accountId")} </TableCell>
                  <TableCell>USDT</TableCell>
                  <TableCell>{translate("sourceType")} </TableCell>

                  {/* <TableCell>
                    {translate("userFinancial.requestPayout.coin")}
                  </TableCell> */}

                  <TableCell>{translate("date")}</TableCell>
                </TableRow>
              </TableHead>
              {data?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <EmptyContent title="Data No Available" />
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <TableBody>
                {data?.map((item, i) => (
                  <TableRow>
                    <TableCell>{i + rowStart}</TableCell>
                    <TableCell>{item.username}</TableCell>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell> {capitalCase(item.type || "")}</TableCell>
                    <TableCell>
                      <ParseDate>{item.date}</ParseDate>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      {/* <PaginationButtons {...rest} /> */}
    </>
  );
};

export default DataList;
