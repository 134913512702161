import moment from "moment";

const serializeDate = (date = null) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  }

  return null;
};

export default serializeDate;
