import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "src/components/hook-form";
import Iconify from "src/components/Iconify";
import useAuth from "src/hooks/useAuth";
import { PATH_AUTH, PATH_DASHBOARD, PATH_USER } from "src/routes/paths";
import axiosInstance from "src/utils/axios";
import { setSession } from "src/utils/jwt";
import { useTheme } from "@mui/material/styles";
import Message from "src/pages/auth/Login/components/MainSection/Message";
import useGoogleOAuth from "./hooks/useGoogleOAuth";
import axios from "axios";

export default function LoginForm({
  close,
  handleClickOpenRegister,
  handleCloseLogin,
}) {
  const gLogin = useGoogleOAuth();
  const { palette } = useTheme();
  const { login, getUser, user, isAdmin } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  // operatingSystem

  const [operatingSystem, setOperatingSystem] = useState("");

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    let detectedOS = "";

    if (userAgent.match(/Win/i)) {
      detectedOS = "Windows";
    } else if (userAgent.match(/Mac/i)) {
      detectedOS = "MacOS";
    } else if (userAgent.match(/Linux/i)) {
      detectedOS = "Linux";
    } else if (userAgent.match(/Android/i)) {
      detectedOS = "Android";
    } else if (userAgent.match(/iOS/i)) {
      detectedOS = "iOS";
    } else {
      detectedOS = "Unknown";
    }

    setOperatingSystem(detectedOS);
  }, []);

  // End operatingSystem

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Account ID is required"),
    password: Yup.string().required("Passcode is required"),
  });

  const [openVerify, setOpenVerify] = useState(false);
  const handleClickOpen = () => {
    setOpenVerify(true);
  };
  const handleClose = () => {
    setOpenVerify(false);
  };

  const [isEmailNotVerified, setIsEmailNotVerified] = useState(true);

  const [openOtp, setOpenOtp] = useState(false);
  const [secret, setSecret] = useState("");
  const [otp, setOtp] = useState("");
  const defaultValues = {
    username: "",
    password: "",
    operatingSystem: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    data.operating_system = operatingSystem;
    const {
      status,
      data: res,
      message,
      isNotVerified,
      secret,
      isAdminCheck,
    } = await login(
      data.username,
      data.password,
      data.remember,
      data.operating_system
    );

    if (status) {
      getUser();
      enqueueSnackbar(`Welcome ${res}`);
      if (isAdminCheck === 1) {
        navigate(PATH_DASHBOARD.root, { replace: true });
      } else {
        navigate(PATH_USER.root, { replace: true });
      }
      reset();
    } else if (secret) {
      setSecret(secret);
      setOpenOtp(true);
      // setOpenVerify(true);
    } else if (isNotVerified) {
      setIsEmailNotVerified(true);
      setError("afterSubmit", {
        message,
      });
    } else {
      setIsEmailNotVerified(false);
      setError("afterSubmit", {
        message,
        isNotVerified,
      });
    }
  };
  const [loading, setLoading] = useState(false);
  const TfaLogin = async () => {
    const reqData = new FormData();
    // reqData.append("operating_system", operatingSystem);
    const [username, password] = getValues(["username", "password"]);
    reqData.append("username", username);
    reqData.append("password", password);
    reqData.append("secret", secret);
    reqData.append("code", otp);
    reqData.append("verify", 1);

    try {
      setLoading(true);
      const { data, status, message } = await axiosInstance.post(
        "api/twofaverify",
        reqData
      );

      if (status === 200) {
        setLoading(false);
        localStorage.setItem("isAdmin", Boolean(data.user.is_super_admin));
        setSession(data?.access_token);
        if (data.user.is_super_admin === 1) {
          window.location = "/admin/dashboard/business";
        } else {
          window.location = "/user/dashboard";
        }
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const EmailReVerify = async () => {
    const reqData = new FormData();
    const [username, password] = getValues(["username", "password"]);
    reqData.append("username", username);
    reqData.append("password", password);

    try {
      const { data, status, message } = await axiosInstance.post(
        "api/re-verify",
        reqData
      );

      if (status === 200) {
        // localStorage.setItem("isAdmin", Boolean(data.user.is_super_admin));
        // setSession(data.access_token);
        // navigate(0);
        handleClose();
        handleCloseLogin();
        enqueueSnackbar(data.message, { variant: "success" });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  return (
    <>
      <Message />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && (
            <Alert
              severity="error"
              action={
                <>
                  {isEmailNotVerified ? (
                    <Button
                      size="small"
                      // component={RouterLink}
                      // to={PATH_AUTH.resendVerifyAccount}
                      onClick={EmailReVerify}
                      style={{ textTransform: "none" }}
                    >
                      Resend eMail
                    </Button>
                  ) : null}
                </>
              }
            >
              {errors.afterSubmit.message}
            </Alert>
          )}

          <RHFTextField name="username" label="Account ID" />

          <RHFTextField
            name="password"
            label="Passcode"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 1 }}
        >
          <RHFCheckbox name="remember" label="Remember me" />
          <Link
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.resetPassword}
            gutterBottom
          >
            Forgot Passcode?
          </Link>
        </Stack>
        <Box sx={{ mb: 2, textAlign: "right" }}>
          <Link
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.resetAccountId}
          >
            Forgot Account ID?
          </Link>
        </Box>

        <LoadingButton
          sx={{ mt: 2 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </FormProvider>
      {/* <Typography variant="body2" sx={{ mt: 3, mb: -4, textAlign: "right" }}>
        Don’t have an account ? &nbsp;
        <Button
          sx={{ color: "#168E91 !important" }}
          onClick={handleClickOpenRegister}
        >
          Register
        </Button>
      </Typography> */}
      <Dialog maxWidth="xs" fullWidth open={openOtp}>
        <DialogContent>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            Two-Factor Authentication (2FA)
          </Typography>
          <Stack spacing={3}>
            <TextField
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              label="Enter OTP"
              type="number"
            />

            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={TfaLogin}
            >
              Submit
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openVerify}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            eMail ID Verifcation
          </Typography>
          <Stack spacing={3}>
            <TextField {...methods.register("username")} label="Account ID" />

            <TextField
              {...methods.register("password")}
              label="Passcode"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="text"
              onClick={EmailReVerify}
              size="large"
              sx={{
                color: palette.primary.contrastText,
                backgroundColor: palette.primary.main,
              }}
            >
              Resend
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
