import { Navigate } from "react-router-dom";
import { PATH_AFTER_LOGIN } from "src/config";
import AuthGuard, { AdminGuard } from "src/guards/AuthGuard";
import AdminLayout from "src/layouts/admin";
import Director from "src/pages/directors/index.js";
import routes from "./routes";

const admin = [
  {
    path: "admin",
    element: (
      <AuthGuard>
        <AdminGuard>
          <AdminLayout />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      {
        path: "directors",
        element: <Director />,
      },
      ...routes,
    ],
  },
];

export default admin;
