import { LensTwoTone } from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Dialog,
  Drawer,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useMemo, useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import Header from "src/components/root_page/layout/header.js";
import useAuth from "src/hooks/useAuth";
import MainSection from "src/pages/auth/Login/components/MainSection/index.jsx";
import Register from "src/pages/auth/Register";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Emailmessage from "src/sections/auth/register/emailMessage";
import "./test/style.css";
import bitz from "src/images/bitzstox@4x.png";
import slide1 from "src/images/slide1.jpg";
import slide2 from "src/images/slide2.jpg";
import slide3 from "src/images/slide3.jpg";
import slide4 from "src/images/slide4.jpg";
import slide5 from "src/images/slide5.jpg";
import { Carousel, CarouselItem, CarouselCaption } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import users from "src/images/loginUser.png";
import Scrollbar from "../Scrollbar";
const Index = () => {
  const { user, logout, isAdmin } = useAuth();
  const isLoggedIn = !user;
  const theme = useTheme();
  const { uname } = useParams();

  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [openRegister, setOpenRegister] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);

  const handleClickOpenRegister = () => {
    setOpenRegister(true);
    handleCloseLogin(true);
  };
  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const handleClickOpenLogin = () => {
    setOpenLogin(true);
    handleCloseRegister(true);
  };
  const handleCloseLogin = () => {
    setOpenLogin(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenDrawer(false);
  };

  const [openMessage, setOpenMessage] = useState();

  const handleClickOpenMessage = () => {
    setOpenMessage(true);
  };
  const handleCloseMessage = () => {
    setOpenMessage(false);
  };
  useEffect(() => {
    if (uname) {
      handleClickOpenRegister();
    }
  }, [uname]);

  // const fetchBrandSettings = async () => {
  //   try {
  //     const { data, status } = await axiosInstance("api/brand-details");
  //     if (status === 200) {
  //       const { logo, side_bar_logo, favicon } = data.data;

  //       if (logo) {
  //         localStorage.setItem("logo", logo);
  //         localStorage.setItem("side_bar_logo", side_bar_logo);
  //         localStorage.setItem("favicon", favicon);
  //       }
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // useEffect(() => {
  //   fetchBrandSettings();
  // }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setOpenDrawer(false);
    }
  }, [isMobile]);
  const buttonStyle = {
    background: "linear-gradient(to right, #168E91, #11565A)",
    border: 0,
    padding: "8px 15px",
    color: "#fff",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "13px",
    fontWeight: "bold",
    outline: "none",
    marginBottom: "10px",
  };
  const [bitzClubAnchorEl, setBitzClubAnchorEl] = useState(null);

  const handleBitzClubClick = (event) => {
    setBitzClubAnchorEl(event.currentTarget);
  };

  const handleBitzClubClose = () => {
    setBitzClubAnchorEl(null);
  };

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>BITZSTOX</title>
        <link rel="stylesheet" href="style.css" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <body>
        <Carousel
          controls={false} // Hide default controls, can be adjusted as needed
          interval={3000} // Interval for auto-sliding, in milliseconds
          pause={false} // Disable auto-pausing on hover
          className="carousel-fade" // Optional: add fade effect between slides
        >
          <Carousel.Item>
            <div class="image-container">
              <img src={slide1} alt="First slide" />
            </div>

            <Carousel.Caption>
              <h5>
                <span>Navigating the Future of</span>
                <br />
                Finance with Digital Assets
              </h5>
              <p style={{ fontSize: "15px" }}>
                Are you ready to take control of your financial future in the
                world of cryptocurrency? At BITZSTOX, we're here to help you
                navigate the dynamic realm of digital assets with confidence and
                expertise. Whether you're planning for your child's future,
                seeking to supplement your income, or preparing for a fulfilling
                retirement through crypto investments, we have the solutions you
                need.
              </p>
              {/* <button onClick={handleClickOpenRegister} style={buttonStyle}>
                REGISTER NOW
              </button> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div class="image-container">
              <img className="d-block" src={slide2} alt="Second slide" />
            </div>
            <Carousel.Caption>
              <h5>Are you a parent?</h5>
              <p style={{ fontSize: "15px" }}>
                Have you saved or invested anything for your child's future? Let
                us help you secure their dreams with our tailored crypto
                investment strategies.
              </p>
              {/* <button onClick={handleClickOpenRegister} style={buttonStyle}>
                REGISTER NOW
              </button> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div class="image-container">
              <img className="d-block w-100" src={slide3} alt="Third slide" />
            </div>
            <Carousel.Caption>
              <h5>Preparing for Retirement?</h5>
              <p style={{ fontSize: "15px" }}>
                Do you have a plan for a happy, stress-free retirement life with
                crypto investments? We'll guide you with expert advice and
                strategic crypto investments to ensure your golden years are
                truly golden.
              </p>
              {/* <button onClick={handleClickOpenRegister} style={buttonStyle}>
                REGISTER NOW
              </button> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div class="image-container">
              <img className="d-block" src={slide4} alt="Third slide" />
            </div>
            <Carousel.Caption>
              <h5>Struggling with your Monthly Salary?</h5>
              <p style={{ fontSize: "15px" }}>
                Is it not enough to meet your bills? Discover how our innovative
                crypto trading platform and personalized services can help you
                achieve financial stability in the digital economy.
              </p>
              {/* <button onClick={handleClickOpenRegister} style={buttonStyle}>
                REGISTER NOW
              </button> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div class="image-container">
              <img className="d-block" src={slide5} alt="Third slide" />
            </div>
            <Carousel.Caption>
              <h5>
                <span> Join us in shaping the future of</span>
                <br />
                Digital Assets SIP With BITZSTOX
              </h5>
              <p style={{ fontSize: "15px" }}>
                your financial goals in the world of cryptocurrency are within
                reach, and innovation drives success. Welcome to a world where
                your financial dreams can become reality. Welcome to BITZSTOX.
              </p>
              {/* <button onClick={handleClickOpenRegister} style={buttonStyle}>
                REGISTER NOW
              </button> */}
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        {isMobile ? (
          // <Toolbar>
          <AppBar
            position="fixed"
            sx={{
              p: 2,
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#9c9c9c",
            }}
          >
            <img src={bitz} style={{ width: "150px" }} />

            <IconButton
              aria-expanded={open ? "true" : undefined}
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleClick}
              sx={{ width: "50px" }}
            >
              <MenuIcon />
            </IconButton>
          </AppBar>
        ) : (
          // </Toolbar>
          <nav className="navlanding">
            <div class="containerox">
              <div>
                <img width={200} src={bitz} />
              </div>
              <div>
                <ul>
                  <li style={{ fontSize: "15px" }}>
                    {" "}
                    <a
                      href="/"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      PMS
                    </a>
                  </li>
                  <li style={{ fontSize: "15px" }}>Staking</li>
                  <li style={{ fontSize: "15px" }}>ETF</li>
                  <li style={{ fontSize: "15px" }}>Bitz Club</li>
                  <li style={{ fontSize: "15px" }}>Gallery</li>
                  <li style={{ fontSize: "15px" }}>
                    {" "}
                    <a
                      href="/faq"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      FAQ
                    </a>
                  </li>
                  <li style={{ fontSize: "15px" }}>Academy</li>
                  <li style={{ fontSize: "15px" }}>News</li>
                  <li>
                    {" "}
                    {isLoggedIn ? (
                      <button
                        onClick={handleClickOpenLogin}
                        style={buttonStyle}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <img
                            width={20}
                            src={users}
                            alt="User Icon"
                            style={{ marginRight: "8px" }}
                          />
                          LOG IN
                        </span>
                      </button>
                    ) : (
                      <>
                        {isAdmin ? (
                          <Link
                            to="/admin/dashboard/business"
                            className="nav-link loginBtn"
                            style={{
                              color: "#fff",
                              textDecoration: "none",
                              border: "1px #fff solid",
                              borderRadius: "8px",
                            }}
                          >
                            Dashboard
                          </Link>
                        ) : (
                          <Link
                            to="/user/dashboard"
                            className="nav-link loginBtn"
                            style={{
                              color: "#fff",
                              textDecoration: "none",
                              border: "1px #fff solid",
                              borderRadius: "8px",
                            }}
                          >
                            Dashboard
                          </Link>
                        )}
                      </>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        )}

        <Menu
          className="menuPopup"
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {" "}
          <NavLink to="/" style={{ color: "#fff", textDecoration: "none" }}>
            <MenuItem onClick={handleClose}>PMS</MenuItem>
          </NavLink>
          <NavLink to="/" style={{ color: "#fff", textDecoration: "none" }}>
            <MenuItem onClick={handleClose}>Staking</MenuItem>
          </NavLink>
          <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
            <MenuItem onClick={handleClose}>ETF</MenuItem>
          </NavLink>
          <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
            <MenuItem onClick={handleClose}>Bitz Club</MenuItem>
          </NavLink>
          <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
            <MenuItem onClick={handleClose}>Gallery</MenuItem>
          </NavLink>
          <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
            <MenuItem onClick={handleClose}>FAQ</MenuItem>
          </NavLink>
          <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
            <MenuItem onClick={handleClose}>Academy</MenuItem>
          </NavLink>
          <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
            <MenuItem onClick={handleClose}>News</MenuItem>
          </NavLink>
          <li>
            {" "}
            {isLoggedIn ? (
              <li
                style={{ marginLeft: "15px", cursor: "pointer" }}
                onClick={handleClickOpenLogin}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  LOG IN
                </span>
              </li>
            ) : (
              <>
                {isAdmin ? (
                  <Link
                    to="/admin/dashboard/business"
                    className="nav-link loginBtn"
                    style={{
                      color: "#fff",
                      textDecoration: "none",
                      border: "1px #fff solid",
                      borderRadius: "8px",
                    }}
                  >
                    Dashboard
                  </Link>
                ) : (
                  <Link
                    to="/user/dashboard"
                    className="nav-link loginBtn"
                    style={{
                      color: "#fff",
                      textDecoration: "none",
                      border: "1px #fff solid",
                      borderRadius: "8px",
                    }}
                  >
                    Dashboard
                  </Link>
                )}
              </>
            )}
          </li>{" "}
        </Menu>
      </body>

      <Dialog
        className="overFlowModal"
        fullScreen={fullScreen}
        open={openRegister}
        // onClose={handleCloseRegister}
      >
        <Box sx={{ maxHeight: 1200, overflow: "scroll" }}>
          <Register
            setEmailMessage={setEmailMessage}
            message={handleClickOpenMessage}
            handleCloseRegister={handleCloseRegister}
            handleClickOpenLogin={handleClickOpenLogin}
          />
        </Box>
      </Dialog>

      <Dialog
        className="overFlowModal"
        fullScreen={fullScreen}
        open={openLogin}
        onClose={handleCloseLogin}
      >
        <MainSection
          handleCloseLogin={handleCloseLogin}
          cancel={handleCloseLogin}
          handleClickOpenRegister={handleClickOpenRegister}
        />
      </Dialog>

      <Dialog
        className="overFlowModalemail"
        fullScreen={fullScreen}
        open={openMessage}
        onClose={handleCloseMessage}
      >
        <Emailmessage
          handleClickOpenLogin={handleClickOpenLogin}
          close={handleCloseMessage}
          emailMessage={emailMessage}
        />
      </Dialog>
    </>
  );
};

export default Index;
