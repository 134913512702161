import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const BinaryTree = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/binary/index"))
);

const BinaryLeg = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/binaryLeg/index"))
);

const Sponsor = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/sponsor/index"))
);

const Affiliate = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/affiliate/index"))
);
const Pool100 = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/Pool100/index"))
);
const Pool250 = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/Pool250/index"))
);
const Pool500 = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/Pool500/index"))
);
const Pool1000 = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/Pool1000/index"))
);

const genealogy = {
  path: "genealogy",
  children: [
    { index: true, element: <Navigate to="binary" /> },
    { path: "binaryLeg", element: <BinaryLeg /> },
    { path: "binary", element: <BinaryTree /> },
    { path: "pool100", element: <Pool100 /> },
    { path: "pool250", element: <Pool250 /> },
    { path: "pool500", element: <Pool500 /> },
    { path: "pool1000", element: <Pool1000 /> },
    { path: "sponsor", element: <Sponsor /> },
    { path: "affiliate", element: <Affiliate /> },
  ],
};

export default genealogy;
