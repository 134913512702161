import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { capitalCase } from "change-case";
import { useSnackbar } from "notistack";
import { createContext, useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import {
  FormProvider,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import LabelStyle from "src/components/label-style";
import Ternary from "src/components/ternary";
import useAuth from "src/hooks/useAuth";
import Countries from "src/pages/userSide/profile/subPages/edit/components/Countries";
import axiosInstance from "src/utils/axios";
import * as Yup from "yup";

const openDialog = createContext(null);

export const useOpenDialog = () => useContext(openDialog);

export default function RegisterForm({
  setEmailMessage,
  handleCloseRegister,
  message,
  handleClickOpenLogin,
  adminRegister,
}) {
  const { uname } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [searchParams] = useSearchParams();
  const typeUser = searchParams.get("user_type");
  const { isAdmin, user } = useAuth();
  const usernameRegex =
    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi;
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email("eMail ID be a valid email address")
      .required("eMail ID is required"),
    first_name: Yup.string()
      .required("Name is required")
      .matches(usernameRegex, " Name only accept characters"),
    plan: Yup.string()
      .typeError("Plan is Required")
      .when("user_type", {
        is: (user_type) => user_type === `investor`,
        then: Yup.string().required("Plan is Required"),
      })
      .nullable(),
    last_name: Yup.string()
      .required("Last Name is required")
      .matches(usernameRegex, "Last Name only accept characters"),

    password: Yup.string()
      .min(8, "Passcode must be at least 8 characters")
      .required("New Passcode is required"),
    repassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passcode must match"
    ),
    referral: Yup.string().required("Referral ID is required"),
    mobile: Yup.string().required("Mobile is required"),

    state: Yup.string().required("State is required"),

    city: Yup.string().required("City is required"),

    country: Yup.string().required("Country is required"),
    nominee_name: Yup.string().required("Nominee Name is required"),
    nominee_mobile: Yup.string().required("Nominee Mobile is required"),
  });

  const defaultValues = {
    nominee_name: "",
    nominee_mobile: "",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    repassword: "",
    referral: "BITZSTOX",
    user_type:
      uname === "customer" ||
      uname === "partner" ||
      uname === "senior_partner" ||
      uname === "agency_partner" ||
      uname === "executive_partner" ||
      uname === "sales_manager" ||
      uname === "regional_manager" ||
      uname === "general_manager"
        ? uname
        : typeUser === "agency_partner"
        ? "senior_partner"
        : typeUser === "executive_partner"
        ? "agency_partner"
        : typeUser === "partner" || isAdmin
        ? "customer"
        : "partner",

    mobile: "",
    city: "",
    country: "",
    state: "",
    plan: "",
    address: "",
    alt_mobile: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = methods;

  useMemo(() => {
    if (
      uname === "partner" ||
      uname === "senior_partner" ||
      uname === "customer" ||
      uname === "agency_partner" ||
      uname === "executive_partner" ||
      uname === "sales_manager" ||
      uname === "regional_manager" ||
      uname === "general_manager"
    ) {
      return null;
    }
    if (uname) {
      methods.setValue("referral", JSON.parse(atob(uname)).username);
    }
  }, [uname]);
  const onSubmit = methods.handleSubmit(async (inputData) => {
    const { user_type, plan, ...rest } = inputData;
    const reqData = new FormData();
    if (user_type === "customer" || uname === "customer") {
      reqData.append("plan", plan);
      reqData.append("user_type", "investor");
    }
    if (user_type === "partner" || uname === "partner") {
      reqData.append("user_type", "partner");
    }
    if (user_type === "senior_partner" || uname === "senior_partner") {
      reqData.append("user_type", "senior_partner");
    }
    if (user_type === "agency_partner" || uname === "agency_partner") {
      reqData.append("user_type", "agency_partner");
    }
    if (user_type === "executive_partner" || uname === "executive_partner") {
      reqData.append("user_type", "executive_partner");
    }

    if (user_type === "sales_manager" || uname === "sales_manager") {
      reqData.append("user_type", "sales_manager");
    }
    if (user_type === "regional_manager" || uname === "regional_manager") {
      reqData.append("user_type", "regional_manager");
    }
    if (user_type === "general_manager" || uname === "general_manager") {
      reqData.append("user_type", "general_manager");
    }

    Object.entries(rest).forEach(([k, v]) => reqData.set(k, v));

    try {
      const { data, status } = await axiosInstance.post(
        "api/register",
        reqData
      );

      if (status === 200) {
        message();
        setEmailMessage(data);
        if (uname) {
          handleCloseRegister();
        }
        methods.reset();
      }
    } catch (error) {
      Object.entries(error.errors).forEach(([k, v]) =>
        setError(k, { message: v[0] })
      );
      setError("afterSubmit", {
        message: error.message.toString(),
      });
    }
  });
  const OPTION = [
    "customer",
    "partner",
    "senior_partner",
    "agency_partner",
    "executive_partner",
    "sales_manager",
    "regional_manager",
    "general_manager",
  ];
  const userType = watch("user_type");
  const planType = watch("plan");

  const RenderUserType = () => {
    switch (typeUser) {
      case "partner":
        return ["customer"];
      case "senior_partner":
        return ["partner"];
      case "agency_partner":
        return ["senior_partner"];
      case "executive_partner":
        return ["agency_partner"];
      case "sales_manager":
        return ["partner", "agency_partner", "executive_partner"];
      case "regional_manager":
        return [
          "partner",
          "agency_partner",
          "executive_partner",
          "sales_manager",
        ];
      case "general_manager":
        return [
          "partner",
          "agency_partner",
          "executive_partner",
          "sales_manager",
          "regional_manager",
        ];
      default:
        return [];
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {isAdmin
            ? ""
            : !!errors?.afterSubmit && (
                <Alert severity="error">{errors?.afterSubmit.message}</Alert>
              )}
          <Box>
            {uname === "partner" ||
            uname === "senior_partner" ||
            uname === "customer" ||
            uname === "agency_partner" ||
            uname === "executive_partner" ||
            uname === "sales_manager" ||
            uname === "regional_manager" ||
            uname === "general_manager" ? (
              ""
            ) : (
              <RHFRadioGroup
                name="user_type"
                options={isAdmin ? OPTION : RenderUserType()}
                sx={{
                  "& .MuiFormControlLabel-root": { mr: 4 },
                  "& .MuiTypography-root": { color: "#168E91" }, // Change text color here
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "grid",
              gap: 2.3,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(2, 1fr)",
              },
            }}
          >
            <RHFTextField
              size="medium"
              name="first_name"
              label=" First Name *"
            />
            <RHFTextField size="medium" name="last_name" label="Last Name *" />
            <RHFTextField size="medium" name="email" label="eMail ID *" />
            <RHFTextField
              size="medium"
              name="password"
              label="Passcode *"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <RHFTextField
              size="medium"
              name="repassword"
              label="Confirm Passcode *"
              type={showRePassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowRePassword(!showRePassword)}
                    >
                      <Iconify
                        icon={
                          showRePassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <RHFTextField
              size="medium"
              name="referral"
              label="Referral ID *"
              disabled={Boolean(uname)}
            />{" "}
            <RHFTextField
              type={"number"}
              size="medium"
              onWheel={(e) => e.target.blur()}
              name="mobile"
              label="Mobile *"
            />{" "}
            <RHFTextField
              type={"number"}
              size="medium"
              onWheel={(e) => e.target.blur()}
              name="alt_mobile"
              label="Alternative Mobile"
            />{" "}
            <Countries />{" "}
            <RHFTextField size="medium" name="state" label="State *" />{" "}
            <RHFTextField size="medium" name="city" label="City *" />{" "}
            <RHFTextField
              size="medium"
              name="address"
              label="Address"
              multiline
            />{" "}
          </Box>
          {userType === "customer" || uname === "customer" ? (
            <RHFSelect name="plan" label={"Plan"}>
              <option />
              <option value={1}>Staking</option>
              <option value={2}>Futures</option>
              <option value={3}>ETF</option>
              <option value={4}>SIP</option>
            </RHFSelect>
          ) : (
            ""
          )}
          <LabelStyle>Nominee Details</LabelStyle>
          <Box
            sx={{
              display: "grid",
              gap: 2.3,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(2, 1fr)",
              },
            }}
          >
            <RHFTextField
              size="medium"
              name="nominee_name"
              label="Nominee Name *"
            />{" "}
            <RHFTextField
              type={"number"}
              onWheel={(e) => e.target.blur()}
              size="medium"
              name="nominee_mobile"
              label="Nominee Mobile *"
            />{" "}
          </Box>

          <Ternary
            when={
              (userType !== "customer" &&
                (planType === "1" ||
                  planType === "" ||
                  planType === "2" ||
                  planType === "3" ||
                  planType === "4")) ||
              (userType === "customer" && planType === "1")
            }
            then={
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Register
              </LoadingButton>
            }
            otherwise={
              <LoadingButton
                disabled
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Coming Soon
              </LoadingButton>
            }
          />
          {!adminRegister && (
            <Typography variant="body2" sx={{ mt: 2, textAlign: "right" }}>
              Already have an account ? &nbsp;
              <Button
                sx={{ color: "#168E91 !important" }}
                onClick={handleClickOpenLogin}
              >
                Login
              </Button>
            </Typography>
          )}
        </Stack>
      </FormProvider>
    </>
  );
}
