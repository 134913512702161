import { Box, Card, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";

const Cards = ({ title, color, total, icon }) => {
  const { translate } = useLocales();
  const lang = localStorage.getItem("i18nextLng");
  return (
    <Card sx={{ display: "flex", alignItems: "center", p: 2 }}>
      <Grid container spacing={1} columns={16}>
        <Grid item xs={10}>
          {" "}
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                whiteSpace: lang === "es" ? "" : "nowrap",
              }}
            >
              {translate(title)}
            </Typography>

            <Typography variant="h4" style={{ whiteSpace: "pre" }}>
              ${total}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ ml: 2 }}>
            <Iconify color={color} width={50} height={50} icon={icon} />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

Cards.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

export default Cards;
