import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useParams } from "react-router";
import email from "src/images/verified.png";
import "./style.css";
import { useTheme } from "@emotion/react";
import MainSection from "src/pages/auth/Login/components/MainSection/index.jsx";
import Iconify from "src/components/Iconify";
import "./style.css";
const Emailmessage = ({ emailMessage, close, handleClickOpenLogin }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { state } = useLocation();
  return (
    <>
      <Box sx={{ textAlign: "right" }}>
        <Tooltip title="Close">
          <IconButton
            aria-label="close"
            onClick={close}
            color="error"
            sx={{
              position: "absolute",
              right: "-10px",
              backgroundColor: "#168E91 !important",
              borderRadius: "50%",
              top: "-18px",
              boxShadow: "2px 7px 10px 1px rgb(3 3 3 / 28%)",
              color: "white",
              right: { md: "-10px", sm: "10px", xs: "10px" },
              top: { md: "-10px", sm: "10px", xs: "10px" },
            }}
          >
            <Iconify icon="material-symbols:close" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ p: 4, pb: 2, mb: 3 }}>
        {/* <Box sx={{ justifyContent: "center", display: "flex" }}>
          <img src={email} style={{ opacity: "1" }} />
        </Box> */}
        <img
          src={email}
          style={{
            opacity: "1",
            height: "120px",
            width: "auto",
            display: "block",
            margin: "30px auto 30px",
          }}
        />
        {/* <Box sx={{ justifyContent: "center", display: "grid", mt: 9, mb: -30 }}>
        <img src={email} style={{ opacity: "1", height: "25%" }} />
      </Box> */}
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "18px !important",
          }}
          align="center"
          gutterBottom
        >
          {emailMessage.message}
        </Typography>
      </Box>
      {/* <Box sx={{ justifyContent: "center", display: "grid", mt: 0, mb: 4 }}>
        <Button
          onClick={() => {
            handleClickOpenLogin();
            close();
          }}
          size="medium"
          variant="contained"
          sx={{ borderRadius: "4px", color: "#fff !important" }}
        >
          Login
        </Button>
      </Box> */}
    </>
  );
};

export default Emailmessage;
