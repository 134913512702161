import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, Link, ListItemText } from "@mui/material";
//
import { capitalCase } from "change-case";
import { ca } from "date-fns/locale";
import SvgIconStyle from "src/components/SvgIconStyle";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import { isExternalLink } from "..";
import Iconify from "../../Iconify";
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from "./style";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

NavItemRoot.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemRoot({
  item,
  isCollapse,
  open = false,
  active,
  onOpen,
}) {
  const { translate } = useLocales();
  const { palette } = useTheme();
  const {
    title,
    path,
    icon,
    info,
    children,
    active: isEnabled,
    is_director,
    is_userType,
    is_sales,
    is_purchased,
    is_partner,
  } = item;
  const isActive = Boolean(isEnabled);
  const { isSubAdmin, user, isAdmin } = useAuth();

  if (Boolean(user?.is_director) && !isAdmin && !isSubAdmin) {
    if (!is_director) return null;
  }
  if (user?.user_type !== "investor" && !isAdmin && !isSubAdmin) {
    if (is_userType) return null;
  }
  if (user?.user_type === "investor" && !isAdmin && !isSubAdmin) {
    if (is_sales) return null;
  }
  if (
    (user?.user_type === "partner" ||
      user?.user_type === "investor" ||
      user?.user_type === "sales_manager" ||
      user?.user_type === "regional_manager" ||
      user?.user_type === "general_manager") &&
    !isAdmin &&
    !isSubAdmin
  ) {
    if (is_partner) return null;
  }

  if (isSubAdmin && !isActive) return null;

  const isDark = palette.mode === "dark";

  const renderContent = (
    <>
      {icon && (
        <ListItemIconStyle>
          <SvgIconStyle src={icon} sx={{ width: 1, height: 1 }} />
        </ListItemIconStyle>
      )}
      <ListItemTextStyle
        disabled={user.payment_status === 0 && is_purchased === false}
        disableTypography
        primary={capitalCase(translate(title))}
        isCollapse={isCollapse}
        sx={{ color: isDark ? "#fff" : "default" }}
        className="font-poppins"
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle
        disabled={user.payment_status === 0 && is_purchased === false}
        onClick={onOpen}
        activeRoot={active}
      >
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      disabled={user.payment_status === 0 && is_purchased === false}
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      disabled={user.payment_status === 0 && is_purchased === false}
      component={RouterLink}
      to={path}
      activeRoot={active}
    >
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

NavItemSub.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemSub({ item, open = false, active = false, onOpen }) {
  const { translate } = useLocales();
  const { palette } = useTheme();
  const { isSubAdmin, user, isAdmin } = useAuth();
  const isDark = palette.mode === "dark";
  const {
    title,
    path,
    info,
    children,
    is_director,
    is_userType,
    is_purchased,
  } = item;
  if (Boolean(user?.is_director) && !isAdmin && !isSubAdmin) {
    if (!is_director) return null;
  }
  if (user?.user_type === "sales_partner" && !isAdmin && !isSubAdmin) {
    if (is_userType) return null;
  }
  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={translate(title)} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle
        disabled={user.payment_status === 0 && is_purchased === false}
        onClick={onOpen}
        activeSub={active}
        subItem
        className="font-poppins"
        sx={{ color: isDark ? "#fff" : "default" }}
      >
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      disabled={user.payment_status === 0 && is_purchased === false}
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      subItem
      className="font-poppins"
      sx={{ color: isDark ? "#fff" : "default" }}
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      disabled={user.payment_status === 0 && is_purchased === false}
      component={RouterLink}
      to={path}
      activeSub={active}
      subItem
      className="font-poppins"
      sx={{ color: isDark ? "#fff" : "default" }}
    >
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: "50%",
          bgcolor: "text.disabled",
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: "scale(2)",
            bgcolor: "primary.main",
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Iconify
      icon={open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
