import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Iconify from "src/components/Iconify";
import htmlFile from "./term";

const Policy = () => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <ul class="bannerfooternav">
        <li>
          <a href="#" onClick={handleClickOpen("paper")}>
            Policy
          </a>
        </li>
        <li>
          <a href="#terms" onClick={handleClickOpen("paper")}>
            Terms of Service
          </a>
        </li>
        <li>
          <a href="#disclosure" onClick={handleClickOpen("paper")}>
            Disclosure
          </a>
        </li>
        <li>
          <a href="#cookies" onClick={handleClickOpen("paper")}>
            Cookies
          </a>
        </li>
      </ul>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div dangerouslySetInnerHTML={{ __html: htmlFile }} />
            {/* <Typography
              variant="subtitle1"
              textAlign="center"
              sx={{ mb: 1, color: "#fff" }}
            >
              Privacy Policy
            </Typography>
            <Typography>
              Thank you for using XORBIT.com. We are committed to protecting
              your privacy and, for that reason, we have adopted this Privacy
              Policy to explain our data collection, use, and disclosure
              practices for the XORBIT.com services (S. S.A its website, and
              mobile and web-based applications, and any other tools, products,
              or services provided by XORBIT, S.A that link to or reference this
              Privacy Policy) (collectively, the “Services”). The Services are
              owned and operated by XORBIT, S.A, a El Salvador limited liability
              company (“we”, “us” or “our”).
            </Typography>
            <Typography mt={1}>
              This Privacy Policy applies to information XORBIT, S.A collects
              through the Services, as well as other information provided to us
              online or offline by third parties, when we associate that
              information with customers or users of the Services; however, it
              does not apply to information collected from our employees,
              contractors, or vendors. It also does not apply to information
              that you share with third parties or is collected by third parties
              through the Services (S.S.A web traffic or crash analytic tools).
              You acknowledge and agree that XORBIT, S.A is not responsible for
              the data collection or use practices of any other Services user or
              third party utilized in providing the Services.
            </Typography>
            <Typography variant="subtitle2" textAlign="center">
              This Privacy Policy describes, among other things:
            </Typography>
            <ul style={{ padding: "1rem" }}>
              <li style={{ listStyle: "disc" }}>
                Personal and other information we collect about you;
              </li>
              <li style={{ listStyle: "disc" }}>
                How we use your information;
              </li>
              <li style={{ listStyle: "disc" }}>
                How we may share your information with third parties; and
              </li>
              <li style={{ listStyle: "disc" }}>
                Your choices regarding the personal information we collect about
                you.
              </li>
            </ul>
            <Typography variant="subtitle2" my={1}>
              1. Consent
            </Typography>
            <Typography>
              By accessing or using the Services, you consent to this Privacy
              Policy. If you do not agree with this Privacy Policy, please do
              not access or use the Services. Information gathered through the
              Services may be transferred, used, and stored in the United States
              or in other countries where our service providers or we are
              located. If you use the Services, you agree to the transfer, use,
              and storage of your Personal Information (as defined below) in
              those countries. The data protection and other laws of the United
              States and other countries might not be as comprehensive as those
              in your country. You agree that all transactions relating to the
              Services or XORBIT.com are deemed to occur in the United States,
              where our servers are located.
            </Typography>
            <Typography variant="subtitle2" my={1}>
              2. Collection of Your Personal and Other Information
            </Typography>
            <Typography>
              When you register for, or use our Services, we collect Personal
              Information. By “Personal Information” we mean information that
              can identify or reasonably be linked to an individual, such as:
            </Typography>
            <ul style={{ padding: "1rem" }}>
              <li style={{ listStyle: "disc" }}>Names</li>
              <li style={{ listStyle: "disc" }}>
                Personal or business addresses
              </li>
              <li style={{ listStyle: "disc" }}>Email addresses</li>
              <li style={{ listStyle: "disc" }}>Phone numbers</li>
              <li style={{ listStyle: "disc" }}>Social Security numbers</li>
              <li style={{ listStyle: "disc" }}>
                Government-issued identification numbers
              </li>
              <li style={{ listStyle: "disc" }}>
                Credit card information (which you submit for payment purposes)
              </li>
            </ul>
            <Typography>
              Our Services may integrate with social media platforms, S.S.A
              Facebook, Twitter, LinkedIn and YouTube When you connect a social
              media account to our Services, then we may collect information
              about that social media account and share information with that
              social media account as described in the connection process. This
              collected information may S.Alude, but is not limited to, your
              name, email address, demographic information from your profile,
              friend lists, postings or other content, and your profile picture.
              You acknowledge and agree that XORBIT, S.A is not responsible for
              the data collection or use practices of any such connected social
              media platform. You should read each social media platform’s
              privacy policy before connecting that social media account.
            </Typography>
            <Typography>
              Certain aspects of the Services may also access certain features
              of your mobile device or web browser, S.S.A its location services
              (GPS) and collect information from those features, such as your
              precise location. You consent to this access.
            </Typography>
            <Typography>
              You may choose not to provide Personal Information or prevent the
              Services from accessing certain features of your mobile device,
              (subject to the controls offered by your mobile device’s operating
              system), but this may prevent you from receiving certain features
              of the Services.
            </Typography>
            <Typography>
              We also collect non-Personal Information relating to the Services,
              that is, information that does not personally identify an
              individual. The non-Personal Information we collect S.Aludes how
              you interact with the Services, information generally collected or
              “logged” by Internet websites or Internet services when accessed
              or used by users, and information about your web browser or device
              accessing or using the Services
            </Typography>
            <Typography mt={1}>
              Examples of the non-Personal Information we collect are:
            </Typography>
            <ul style={{ padding: "1rem" }}>
              <li style={{ listStyle: "disc" }}>
                Your Internet Protocol (IP address)
              </li>
              <li style={{ listStyle: "disc" }}>
                Information about your computer or mobile device, such as its
                maker or operating system version, and HTTP header information
              </li>
              <li style={{ listStyle: "disc" }}>
                The pages of our website that you viewed during a visit
              </li>
              <li style={{ listStyle: "disc" }}>
                What information, content or advertisements you view or interact
                with using the Services
              </li>
              <li style={{ listStyle: "disc" }}>Language preferences</li>
              <li style={{ listStyle: "disc" }}>
                The city and state in which you are located (but not your
                precise geographic location)
              </li>
              <li style={{ listStyle: "disc" }}>
                Unique identifiers that are not connected and cannot reasonably
                be connected to your identity
              </li>
            </ul>
            <Typography>
              Finally, our Services may also collect or retain information you
              provide to vendors or other third parties regarding products or
              services in which you’ve expressed an interest using the Services,
              such as your credit score, credit rating, or other demographic
              information.
            </Typography>
            <Typography>
              If we associate any non-Personal Information with information that
              personally identifies you, then we will treat it as Personal
              Information. We may also aggregate Personal Information and
              non-Personal Information in a manner such that the end-product
              does not personally identify you or any other user of XORBIT.com,
              for example, by using Personal Information to calculate the
              percentage of our users who have a particular telephone area code
              and/or zip code. As discussed in more detail below, we sometimes
              use cookies and other automatic information gathering technologies
              to gather nonPersonal Information.
            </Typography>
            <Typography>
              Information collected by the Services may be collected by us or
              one of our service providers acting on our behalf, but in either
              case, this Privacy Policy will govern the collection, use, and
              sharing of the information.
            </Typography>
            <Typography variant="subtitle2" my={1}>
              3. Use of Your Information
            </Typography>
            <Typography>We may use the information we collect to:</Typography>
            <ul style={{ padding: "1rem" }}>
              <li style={{ listStyle: "circle" }}>
                Connect you with vendors who will provide you with information
                about products or services in which you’ve expressed an interest
                using the Services
              </li>
              <li style={{ listStyle: "circle" }}>
                Assist us in providing, maintaining, and protecting the Services
              </li>
              <li style={{ listStyle: "circle" }}>
                Set up, maintain, and protect accounts to use the Services
              </li>
              <li style={{ listStyle: "circle" }}>
                Improve our online operations
              </li>
              <li style={{ listStyle: "circle" }}>Process transactions</li>
              <li style={{ listStyle: "circle" }}>Provide customer service</li>
              <li style={{ listStyle: "circle" }}>
                Communicate with you, such as provide you with account- or
                transaction—related communications, or other newsletters, RSS
                feeds, and/or other communications relating to the Services
              </li>
              <li style={{ listStyle: "circle" }}>
                Send or display offers and other content that is customized to
                your interests or preferences, S.S.A your Service activity
              </li>
              <li style={{ listStyle: "circle" }}>
                Perform research and analysis aimed at improving our products
                and services and developing new products or services
              </li>
              <li style={{ listStyle: "circle" }}>
                Manage and maintain the systems that provide the Services
              </li>
            </ul>
            <div id="disclosure">
              <Typography variant="subtitle2" my={1}>
                4. Disclosure of Your Information
              </Typography>
              <Typography>
                We may disclose your Personal Information to third parties as
                described below. We may disclose Personal Information to provide
                the Services, or when you authorize or instruct us to do so, for
                example when you use the Services to submit content or profile
                information. Some aspects of the Services are a social
                experience, so we may disclose your interactions with the
                Services to social media platforms, as described in Section 2,
                above. We may also disclose Personal Information and
                non-Personal Information to companies, agents, contractors,
                service providers, or others engaged to perform functions on our
                behalf (such as processing of payments, provision of data
                storage, hosting of our website, marketing of our products and
                services, conducting audits, sending e-mail newsletters, and
                performing web analytics). We may license third party software
                to S.S.A in or use with the Services, in which case we may
                disclose Personal Information and/or Non-Personal Information to
                the licensor
              </Typography>
              <Typography>
                We may partner with advertisers or other affiliates to provide
                you with special offers, or to advertise products or services to
                you. For example, if you request information about a particular
                Crypto product or service, the Personal Information you provide
                is shared with the relevant third parties necessary to fulfill
                your request. We may share this Personal Information with
                providers of Crypto products and services, as well as
                third-party finder and lead aggregator companies who will pass
                along your information to help fulfill your request. By entering
                your Personal Information and clicking on “Submit” or any
                similar button, you are authorizing the sharing of your inquiry
                and your Personal Information with other businesses who provide,
                market, or find Crypto products or services that match your
                inquiry. If you redeem or respond to an offer, we may provide
                your Personal Information to the advertising partner, S. S.A
                your name, email address, gender, and year of birth. If you
                answer questions or fill out surveys from an advertiser, we may
                share information with that advertiser. The advertising
                partner’s privacy policy will govern their use of your
                information, which may S. S.A marketing of other products or
                services to you. You should read each advertiser’s or
                affiliate’s privacy policy before providing information to that
                advertiser or affiliate. For your convenience, a nonexhaustive
                list of the advertisers and affiliates with whom we may share
                your information, S. S.A their respective privacy policies, is
                available here. You should refer to this link regularly, as we
                may add or remove advertisers or affiliates at any time, in our
                sole discretion.
              </Typography>
              <Typography>
                We may also disclose your Personal Information to third parties
                when we believe, in good faith and in our sole discretion, that
                such disclosure is reasonably necessary to (a) enforce or apply
                the terms and conditions of the Services, S.S.A investigation of
                potential violations thereof, (b) comply with legal or
                regulatory requirements or an enforceable governmental request,
                (c) protect the rights, property or safety of us, our users or
                other third parties, (d) prevent a crime or protect national
                security, or (e) detect, prevent or otherwise address fraud,
                security or technical issues.
              </Typography>
              <Typography>
                Finally, we reserve the right to transfer information (S. S.A
                your Personal Information) to a third party in the event of a
                sale, merger, or transfer of all or substantially all of the
                assets of our company relating to the Services, or in the
                unlikely event of a bankruptcy, liquidation, or receivership of
                our business. We will use commercially reasonable efforts to
                notify you of such transfer, for example via email or by posting
                notice on our website.
              </Typography>
              <Typography>
                Lastly, we may also disclose (S.S.A by selling) non-Personal
                Information, aggregated with information about our other users,
                to our clients, business partners, merchants, advertisers,
                investors, potential buyers and other third parties if we deem
                such disclosure, in our sole discretion, to have sound business
                reasons or justifications.
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle2" my={1}>
                5. Cookies and Automatic Information Gathering Technologies
              </Typography>
              <Typography>
                Every time you use the Services (e.g., access a Service Webpage,
                navigate to a specific tool within the Service), we collect
                non-Personal Information (discussed above in Section 2)
                regarding that use. For example, to improve our Services, we
                collect how, when, and which parts of the Services or its
                features you use, which social media platforms you connect to
                the Services, and when, how, and what you post to the social
                media platforms through the Service. Also, we may use your
                device’s unique identifier (UDID), media access control address
                (MAC Address), or other unique identifiers (S.S.A identifiers we
                create) to assist us in collecting and analysing this data.
              </Typography>
              <Typography>
                To assist us in collecting and storing this non-Personal
                Information, we may employ a variety of technologies, S.S.A
                “Cookies,” local browser storage, and “Web Beacons.” A “Cookie”
                is a small amount of data a website operator, or a third party
                whose content is embedded in that website, may store in your web
                browser and that the website operator or, as applicable, the
                third party, can access when you visit the website. A Cookie may
                also refer to web-browser-based storage provided by Adobe’s
                Flash plugin (a “Flash Cookie”). A “Web Beacon” is a small,
                usually-transparent image placed on a web page that allows the
                operator of that image, which may be the operator of the website
                you visit or a third party, to read or write a Cookie.
              </Typography>
              <Typography>
                Your operating system and web browser may allow you to erase
                information stored in Cookies, Flash Cookies, and local browser
                storage. But if you do so, you may be forced to login to the
                Services again and you may lose some preferences or settings.
                You may also be able to set your browser to refuse all website
                storage or to indicate when it is permitted, but some features
                of our Services may not function properly without it. We may use
                Cookies to keep you logged in, save your preferences for the
                Services, to collect information about how you use our Services,
                to help us display content that is customized to your interests
                and preferences, and to improve your experience using the
                Services.
              </Typography>
              <Typography>
                More information about managing Cookies is available here.
                Cookie management tools provided by your browser may not affect
                Flash Cookies. More information about managing Flash Cookies is
                available here. To learn how to manage privacy and storage
                settings for your local browser storage, please refer to the end
                user documentation for your browser.
              </Typography>
              <Typography>
                To help us collect this information, we use third-party software
                and services. These third parties’ collection and use of
                non-Personal Information are subject to their own privacy
                policies, which you can read here:
              </Typography>
              <ul style={{ padding: "1rem" }}>
                <li style={{ listStyle: "square" }}>
                  Google Analytics and Google Tag Manager
                </li>
                <li style={{ listStyle: "square" }}>Optimizely</li>
                <li style={{ listStyle: "square" }}>Hotjar</li>
                <li style={{ listStyle: "square" }}>Cake</li>
                <li style={{ listStyle: "square" }}>Mailchimp</li>
                <li style={{ listStyle: "square" }}>Crazy Egg</li>
                <li style={{ listStyle: "square" }}>Smarty Streets</li>
              </ul>
              <Typography>
                We also work with service providers and third-party advertising
                networks who help us collect nonPersonal Information through
                cookies in order to deliver Interest-Based Advertising (IBA) to
                our users. IBA ads are tailored specifically to your likes,
                based on general categories in which you’ve shown an interest.
                You are often able to learn more about IBA and opt out of
                receiving these types of ads within the ads themselves when you
                see them. For more information on how you can opt-out of IBA,
                please see the “Transparency and Choice” section below.
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle2" my={1}>
                6. Transparency and Choice; Do Not Track Signals
              </Typography>
              <Typography>
                You may request access to your Personal Information by sending
                an email to hello@XORBIT.com. We will try to locate and provide
                you with your Personal Information and give you the opportunity
                to correct this data, if it is inaccurate, or to delete it, at
                your request. But, in either case, we may need to retain it for
                legal reasons or for legitimate business purposes. You may also
                remove any content that you post to the Services using the
                deletion or removal options within the Service. However, we (and
                you) are not able to control information that you have already
                shared with other users or made available to third parties
                through the Services.
              </Typography>
              <Typography>
                If you need further assistance with removing any content you
                posted through the Services, you can email us at
                hello@XORBIT.com. Removal of your posted content may not ensure
                complete or comprehensive removal from our computer systems.
              </Typography>
              <Typography>
                We ask individual users to identify themselves and the
                information requested to be accessed, corrected, or removed
                before processing such requests, and we may decline to process
                requests that are unreasonably repetitive or systematic, require
                disproportionate technical effort, jeopardize the privacy of
                others, would be extremely impractical (for instance, requests
                concerning information residing on backups), or relate to
                information that is not associated with your Personal
                Information. In any case, where we provide information access
                and correction, we perform this service free of charge, except
                if doing so would require a disproportionate effort.
              </Typography>
              <Typography>
                Please be aware that if you request us to delete your Personal
                Information, you may not be able to continue to use the
                Services. Also, even if you request that we delete your Personal
                Information, we may need to retain certain information for a
                limited period of time to satisfy our legal, audit and/or
                dispute resolution requirements.
              </Typography>
              <Typography>
                We may use third-party service providers that collect
                information for interest-based advertising purposes
                (advertisements that are tailored to your likely interests,
                based on categories in which you have shown an interest). To
                learn more about these third parties and the choices they offer
                users, please visit the Network Advertising Initiative’s choices
                page or the Digital Advertising Alliance’s choices page. If you
                are reading this Privacy Policy from a mobile device, you can
                learn more about the DAA’s mobile choices program here.
              </Typography>
              <Typography>
                At this time, XORBIT.com does not respond to “do not track”
                signals that may be sent from your browser.
              </Typography>
              <Typography>
                You can opt out of receiving marketing e-mails from us by
                clicking on the “unsubscribe” link in the emails. Please note
                that it may take up to ten (10) business days for you opt-out
                request to be processed. Also, even if you opt out of marketing
                e-mails, we may continue to send you certain account-related
                e-mails, such as notices about your account and confirmations of
                transactions you have requested.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                7. Children
              </Typography>
              <Typography>
                The Services are not intended for users under 18 years of age.
                We do not knowingly collect Personal Information from users
                under 18 years of age. We do not authorize users under 18 years
                of age to use the Services.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                8. Information Security
              </Typography>
              <Typography>
                We utilize reasonable information security measures to safeguard
                your Personal Information against unauthorized access,
                modification, or destruction. For example, we utilize Secure
                Socket Layer (SSL), Transport Layer Security (TLS), or similar
                encryption technology when sensitive data is transmitted over
                the Internet, and use firewalls to help prevent external access
                into our network. However, no data transmission over the
                Internet and no method of data storage can be guaranteed to be
                100% secure. Therefore, while we strive to use commercially
                acceptable means to protect your Personal Information, we cannot
                guarantee its security.
              </Typography>
              <Typography>
                We restrict access to Personal Information to our employees,
                contractors, and agents who need to know that information in
                order to operate, develop, improve or support our Services. If
                we share Personal Information with service providers, we require
                that they also use reasonable information security measures to
                safeguard your Personal Information, and only use your Personal
                Information for the purposes for which we share it with them.
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle2" my={1}>
                9. Your EL SALVADOR Privacy Rights
              </Typography>
              <Typography>
                A El Salvador resident who has provided Personal Information to
                a business with whom he/she has established a business
                relationship for personal, family, or household purposes (“El
                Salvador Customer”) is entitled to request information about
                whether the business has disclosed Personal Information to any
                third parties for the third parties’ direct marketing purposes.
                In general, if the business has made such a disclosure of
                Personal Information, upon receipt of a request by a El Salvador
                Customer, the business is required to provide a list of all
                third parties to whom Personal Information was disclosed in the
                preceding calendar year, as well as a list of the categories of
                Personal Information that were disclosed.
              </Typography>
              <Typography>
                However, under the law, a business is not required to provide
                the above-described lists if the business adopts and discloses
                to the public (in its privacy policy) a policy of not disclosing
                a customer’s Personal Information to third parties for their
                direct marketing purposes unless the customer first
                affirmatively agrees to the disclosure, as long as the business
                maintains and discloses this policy. Rather, the business may
                comply with the law by notifying the customer of his or her
                right to prevent disclosure of Personal Information to third
                parties for direct marketing purposes and providing a cost free
                means to exercise that right. To prevent disclosure of your
                Personal Information for use in direct marketing by a third
                party for its own purposes, do not opt in to or authorize such
                use when you provide Personal Information through the Services.
                Please note that whenever you allow your Personal Information to
                be shared with a third party to communicate with you, your
                information will be subject to that third party’s privacy
                policy. If you later decide that you do not want that third
                party to use your information, you will need to contact the
                third party directly. You should always review the privacy
                policy of any party that collects your information to determine
                how that entity will handle your information.
              </Typography>
              <Typography>
                El Salvador Customers may request further information about our
                compliance with El Salvador’s privacy law by e-mailing
                hello@XORBIT.com. Please note that we are only required to
                respond to one request per customer each year, and we are not
                required to respond to requests made by means other than through
                this e-mail address.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                10 . Third Party Websites
              </Typography>
              <Typography>
                Please note that the Services may link or integrate with
                third-party sites, services or apps. We are not responsible for
                the privacy or security policies or practices or the content of
                such third parties. Accordingly, we encourage you to review the
                privacy and security policies and terms of service of those
                third parties so that you understand how those websites collect,
                use, share and protect your information
              </Typography>
              <Typography variant="subtitle2" my={1}>
                11 . Changes to this Policy
              </Typography>
              <Typography>
                We may modify or update this Privacy Policy periodically with or
                without prior notice by posting the updated policy on this page.
                You can always check the “Last Updated” date at the top of this
                document to see when the Privacy Policy was last changed. If we
                make any material changes to this Privacy Policy, we will notify
                you by reasonable means, which may be by e-mail or posting a
                notice of the changes on our website prior to the changes
                becoming effective. We encourage you to check this Privacy
                Policy from time to time. IF YOU DO NOT AGREE TO CHANGES TO THIS
                PRIVACY POLICY, YOU MUST STOP USING THE SERVICES AFTER THE
                EFFECTIVE DATE OF SUCH CHANGES (WHICH IS THE “LAST UPDATED” DATE
                OF THIS PRIVACY POLICY).
              </Typography>
              <Typography variant="subtitle2" my={1}>
                12 . Questions
              </Typography>
              <Typography>
                To ask questions about our Privacy Policy or to lodge a
                complaint, contact us at:
              </Typography>
              <Typography>
                XORBIT, S.A, Los Verde, 101 Avenida Norte #517, San Salvador, El
                Salvador Email: hello@XORBIT.com
              </Typography>
            </div>
            <div id="terms">
              <Typography
                variant="subtitle1"
                textAlign="center"
                sx={{ mb: 1, mt: 1, color: "#fff" }}
              >
                Terms of Service
              </Typography>
              <Typography>
                Thanks for using the XORBIT.com services (S.S.A its website and
                web-based applications, and any other tools, products, or
                services provided by XORBIT.com that link to or reference these
                Terms) (collectively, the “Services”). The Services are provided
                by XORBIT, S.A (“XORBIT, S.A”, “we,” “our,” or “us”), located at
                Los Verde, 101 Avenida Norte #517, San Salvador, El Salvador
              </Typography>
              <Typography>
                By using our Services, you are agreeing to these Terms of
                Service (“Terms”). Please read them carefully. Our Services are
                very diverse, so sometimes additional terms or product
                requirements (S.S.A age requirements) may apply. If additional
                terms or conditions are available with or applicable to the
                relevant Services, then those additional terms become part of
                your agreement with us if you use those Services. By accessing
                or using the Services, you intend and agree to be legally bound
                by these Terms. You may wish to print or save a local copy of
                the Terms for your records.
              </Typography>
              <Typography>
                YOU ACKNOWLEDGE AND AGREE THAT THESE TERMS OF SERVICE LIMIT OUR
                LIABILITY AND THAT YOU ARE RELEASING US FROM VARIOUS CLAIMS IN
                SECTION 8 BELOW. THESE TERMS ALSO CONTAIN A BINDING ARBITRATION
                PROVISION IN SECTION 13 THAT AFFECT YOUR RIGHTS UNDER THESE
                TERMS WITH RESPECT TO THE SERVICES.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                1. Using our Services
              </Typography>
              <Typography>
                PLEASE BE ADVISED THAT WE ARE NOT A Crypto INSTITUTION OR A
                PROVIDER OF Crypto PRODUCTS OR SERVICES. We do not provide the
                Crypto products or services described on XORBIT.com. Instead,
                XORBIT.com acts as a venue to allow third-party providers of
                Crypto products and services, such as Crypto institutions,
                credit card providers, mortgage brokers, insurance brokers,
                lenders, and other third parties who are customers of XORBIT.com
                (such as marketing partners and finders working on behalf of
                third-party providers of Crypto products and services) (each, a
                “Service Provider”) to offer Crypto products and services to you
                and other potential consumers (each, a “Consumer”) in a variety
                of formats. We are not involved in the actual transaction
                between any Consumer and any Service Provider, even though we
                may, through XORBIT.com, and with each Consumer’s authorization,
                collect certain information submitted through XORBIT.com about a
                Consumer (collectively, a “Lead”), and sell such Lead to a
                Service Provider. As a result, the quality, safety, or legality
                of the Crypto products and services offered by a Service
                Provider, the ability of a Service Provider to offer, sell, or
                deliver such products or services, or the ability of Consumers
                to pay for the products or services offered are solely the
                responsibility of each Service Provider and Consumer, and not
                XORBIT, S.A, S.A. We are an independent contractor for all
                business purposes. We are not responsible in any way for the
                conduct of any Service Provider or Consumer. Although XORBIT,
                S.A, S.A is compensated by Service Providers when we provide
                Leads or display advertisements or other content promoting their
                products or services, we do not endorse or recommend any Service
                Provider, or any products or services offered by any Service
                Provider. We do not provide Crypto, tax, legal, or any other
                advice. We do not guarantee that any of the Service Providers to
                whom we forward a Consumer’s information will contact such
                Consumer or agree to provide the Consumer with the desired or
                requested Crypto products or services. If you are a Consumer and
                you would like personal Crypto advice, please consult with a
                Crypto adviser or other qualified professional.
              </Typography>
              <Typography>
                In order to provide certain Crypto products and services, or to
                help to verify your identity, Service Providers may obtain,
                verify, and record information that identifies each person who
                inquiries about Crypto products or services from them. For
                example, Service Providers may ask for your name, Social
                Security Number, driver’s license number, address, date of
                birth, or other important information that will allow proper
                identification of you, both internally and with the assistance
                of unaffiliated third parties or credit bureaus through a credit
                check(s). By submitting such information on XORBIT.com, you
                authorize us to provide such information to Service Providers in
                order to help you complete your request (S. S.A providing you
                with information about other products or services in which you
                have expressed an interest), and to enable the Service Providers
                to provide products and services to you. The Crypto products and
                services provided or advertised on and through XORBIT.com may
                not be available in all states, and the availability of such
                products and services on and through XORBIT.com may change from
                time to time and without notice. The data and other information
                you may provide through XORBIT.com is not, and is not treated
                as, an application for, or a request to be pre-approved,
                pre-qualified, or any similar concept for, any Crypto products
                or services. Likewise, XORBIT, S.A does not guarantee that the
                terms, rates, or pricing for the products or services made
                available by the Service Providers through XORBIT.com are the
                best terms or lowest pricing or rates available in the market.
              </Typography>
              <Typography>
                Further, you must also follow any policies made available to you
                within the Services.
              </Typography>
              <Typography>
                Don’t misuse our Services. For example, don’t interfere with our
                Services, try to access them using a method other than the
                interface and the instructions that we provide, or extensively
                or automatically copy any content from the Services (in other
                words, no scraping). You may use our Services only for your
                personal non-commercial use, and as permitted by law, S. S.A
                applicable export and re-export control laws and regulations. We
                may suspend or stop providing our Services to you if you do not
                comply with our terms or policies, if we are investigating
                suspected misconduct, or for any other reason.
              </Typography>
              <Typography>
                Using our Services does not give you ownership of any
                intellectual property rights in our Services or the content you
                access through them (“Content”). You may not use Content, except
                as permitted in these Terms, by its owner, or as otherwise
                permitted by law. These Terms do not grant you the right to use
                any branding or logos used in our Services, S. S.A the
                XORBIT.com and/or XORBIT, S.A names and logos. Don’t remove,
                obscure, or alter any legal notices displayed in or along with
                our Services.
              </Typography>
              <Typography>
                Our Services display some Content that is not our own. For
                example, Content belonging to our Service Providers,
                advertisers, affiliates, other third parties, you, or other
                users (collectively, “Third Party Content”). We are not
                responsible for, and you waive all of our liability with respect
                to, Third Party Content. Third Party Content is the sole
                responsibility of the individual or entity that makes it
                available to you via the Services. We may review Third Party
                Content to determine whether it is illegal or violates our
                policies, and we may remove or refuse to display Third Party
                Content that we believe violates our policies or the law. But we
                do not generally review content beforehand, and we are not
                obligated to do so.
              </Typography>
              <Typography>
                In connection with your use of the Services, we may send you
                service announcements, administrative messages, and other
                information. You may opt out of our marketing emails by clicking
                on the “unsubscribe” link in marketing e-mails. Please be aware
                that there may be a brief period before we are able to process
                you opt-out.XORBIT.com is available on mobile devices, which may
                cause you to S. S.A data charges with your wireless provider.
                Please be aware that we have no control over these charges, and
                if you do not wish to be charged, you should stop using
                XORBIT.com on your mobile device.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                2. Your XORBIT Account
              </Typography>
              <Typography>
                You may need an account in order to use the Services. If you
                create your own account, you agree that all registration
                information you give us will be accurate and current. You will
                timely notify us of any changes to any of the foregoing
                information. You are responsible for controlling access to any
                PCs, mobile devices, or other end points that you allow to store
                your Services password, or on which you enable a “Remember Me”
                or similar functionality (“Activated Device”). Accordingly, you
                agree that you will be solely responsible for all activities
                that occur under your Services accounts, S.S.A the activities of
                any individual with whom you share your Services account or an
                Activated Device, as discussed further below.
              </Typography>
              <Typography>
                To protect your account, keep your password confidential. You
                are responsible for the activity that happens on or through your
                account. If you learn of any unauthorized use of your password,
                please contact us at hello@XORBIT.com.
              </Typography>
              <Typography>
                User verification on the Internet is difficult, and we cannot
                and do not confirm each user’s purported identity. We encourage
                you to use appropriate caution with anyone with whom you may be
                doing business via XORBIT.com or the Internet, generally. When
                you give someone your online ID and online password, you are
                authorizing that person to access and use your account, and you
                are responsible for any and all transactions that person
                performs while using your account, even those transactions that
                are fraudulent or that you did not intend or want performed.
                EACH USER ACKNOWLEDGES AND AGREES THAT: (i) NEITHER XORBIT, S.A
                NOR ANY OF ITS AFFILIATES WILL HAVE ANY LIABILITY TO ANY USER
                FOR ANY UNAUTHORIZED TRANSACTION MADE USING ANY USER’S ONLINE ID
                AND PASSWORD THAT OCCURS BEFORE SUCH USER HAS NOTIFIED US OF
                POSSIBLE UNAUTHORIZED USE OF SUCH ONLINE ID AND PASSWORD AND WE
                HAVE HAD A REASONABLE OPPORTUNITY TO ACT ON THAT NOTICE; AND
                (ii) THE UNAUTHORIZED USE OF YOUR ONLINE ID AND PASSWORD COULD
                CAUSE YOU TO S.AUR LIABILITY TO BOTH XORBIT, S.A, SERVICE
                PROVIDERS, AND OTHER USERS. Further, we may suspend or cancel
                your account or your access to XORBIT.com at any time with or
                without notice if we suspect that your account and/or password
                is being used in an unauthorized or fraudulent manner.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                3. Privacy and Feedback
              </Typography>
              <Typography>
                Our privacy policy explains how we treat your personal
                information and protect your privacy when you use our Services.
                By using our Services, you agree that we can collect, use, and
                share data from you as described in our privacy policy. We are
                not responsible for any information or Content that you share
                with others via your use of the Services. You assume all
                privacy, security, and other risks associated with providing any
                information, S.S.A personally identifiable information, to other
                users of the Service.
              </Typography>
              <Typography>
                If you submit feedback or suggestions about our Services, you
                agree that we may use your feedback or suggestions without
                obligation to you.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                4. Content You Submit or Share
              </Typography>
              <Typography>
                You may submit and share text and other content to or through
                the Services (such as reviews you write and comments you post on
                XORBIT.com) (“Your Content”), and in doing so you must follow
                these Terms and the rules and policies referenced in these
                Terms.
              </Typography>
              <Typography>
                When you upload, submit, or otherwise share Your Content to or
                through our Services, you give us (and those we work with) a
                royalty-free, worldwide license to use, host, store, reproduce,
                modify,create derivative works (such as those resulting from
                translations, adaptations or other changes we make so that Your
                Content works better with our Services), communicate, publish,
                publicly perform, publicly display and distribute Your Content.
                The rights you grant in this license are for the limited purpose
                of operating, promoting, and improving our Services, and to
                develop new ones. This license continues even if you stop using
                our Services. Make sure you have the necessary rights to grant
                us this license for any content that you submit to our Services.
              </Typography>
              <Typography>
                We may publicly display Your Content on the Services (S.S.A
                through third-party software applications that enable you to
                post comments or reviews on XORBIT.com). You may request that we
                delete any of Your Content that you submit to the Services by
                sending us an email at hello@XORBIT.com. To the extent within
                our control, we’ll remove Your Content from public display on
                XORBIT.com and mark it for future deletion if permitted by
                applicable law; however, it may persist in backup or residual
                copies for a reasonable period of time (but will not be
                available to other users through the Services). For purposes of
                clarification, once you submit or share Your Content with others
                via the Services (e.g., other users or third parties), we no
                longer have control over those portions of Your Content and will
                not be able to delete it or prevent them from using it.
              </Typography>
              <Typography mt={1}>
                You agree that you will not use the Services to:
              </Typography>
              <ul style={{ padding: "1rem" }}>
                <li style={{ listStyle: "disc" }}>
                  Violate law or a third-party’s rights;
                </li>
                <li style={{ listStyle: "disc" }}>
                  Submit excessive or unsolicited commercial messages or spam
                  any users
                </li>
                <li style={{ listStyle: "disc" }}>
                  Submit malicious content or viruses
                </li>
                <li style={{ listStyle: "disc" }}>
                  Solicit other people’s login information, credit card numbers,
                  or other sensitive information
                </li>
                <li style={{ listStyle: "disc" }}>
                  Harass or bully other users
                </li>
                <li style={{ listStyle: "disc" }}>
                  Post content that is hate speech, threatening or pornographic,
                  that S.Aites violence or that contains nudity or graphic or
                  gratuitous violence
                </li>
              </ul>
              <Typography variant="subtitle2" my={1}>
                5. INTELLECTUAL PROPERTY PROTECTION
              </Typography>
              <Typography>
                As we ask others to respect our intellectual property rights, we
                respect the intellectual property rights of others, and require
                our users and customers to do so. If you are a copyright owner
                or its agent and believe that any content residing on or
                accessible through the Services infringes upon your copyrights,
                you may submit a notification under the Digital Millennium
                Copyright Act (“DMCA”) by providing our Copyright Agent (the
                “Designated Agent”) with the following information in writing
                (see 17 U.S.C 512(c)(3) for further detail):
              </Typography>
              <ul style={{ padding: "1rem" }}>
                <li style={{ listStyle: "square" }}>
                  Identification of the work or material being infringed.
                </li>
                <li style={{ listStyle: "square" }}>
                  Identification of the material that is claimed to be
                  infringing, S.S.A its location, with sufficient detail so that
                  we are capable of finding it and verifying its existence.
                </li>
                <li style={{ listStyle: "square" }}>
                  Contact information for the notifying party (the “Notifying
                  Party”), S.S.A name, address, telephone number, and email
                  address.
                </li>
                <li style={{ listStyle: "square" }}>
                  A statement that the Notifying Party has a good faith belief
                  that the material is not authorized by the copyright owner,
                  its agent or law.
                </li>
                <li style={{ listStyle: "square" }}>
                  A statement made under penalty of perjury that the information
                  provided in the notice is accurate and that the Notifying
                  Party is authorized to make the complaint on behalf of the
                  copyright owner.
                </li>
                <li style={{ listStyle: "square" }}>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of the copyright that has been
                  allegedly infringed.
                </li>
              </ul>
              <Typography>
                Please also note that the information provided in a notice of
                copyright infringement may be forwarded to the user who posted
                the allegedly infringing content. After removing material in
                response to a valid DMCA notice, we will notify the user
                responsible for the allegedly infringing material that we have
                removed or disabled access to the material. We will terminate,
                under appropriate circumstances, users who are repeat copyright
                infringes, and we reserve the right, in our sole discretion, to
                terminate any user for actual or apparent copyright
                infringement.
              </Typography>
              <Typography mt={1}>
                If you believe you are the wrongful subject of a DMCA
                notification, you may file a counter-notification with us by
                providing the following information to the Designated Agent at
                the address below:
              </Typography>
              <ul style={{ padding: "1rem" }}>
                <li style={{ listStyle: "square" }}>
                  The specific URLs of material that we have removed or to which
                  we have disabled access
                </li>
                <li style={{ listStyle: "square" }}>
                  Your name, address, telephone number, and email address
                </li>
                <li style={{ listStyle: "square" }}>
                  A statement that you consent to the jurisdiction of El
                  Salvador. District Court for the Central District of El
                  Salvador, and that you will accept service of process from the
                  person who provided the original DMCA notification or an agent
                  of such person
                </li>
                <li style={{ listStyle: "square" }}>
                  The following statement: “I swear, under penalty of perjury,
                  that I have a good faith belief that the material was removed
                  or disabled as a result of a mistake or mis-identification of
                  the material to be removed or disabled.”
                </li>
                <li style={{ listStyle: "square" }}>Your signature.</li>
              </ul>
              <Typography>
                Upon receipt of a valid counter-notification, we will forward it
                to Notifying Party who submitted the original DMCA notification.
                The original Notifying Party (or the copyright holder he or she
                represents) will then have ten (10) days to notify us that he or
                she has filed legal action relating to the allegedly infringing
                material. If we do not receive any such notification within ten
                (10) days, we may restore the material to the Services.
              </Typography>
              <Typography>
                The contact information for our Designated Agent is <br />
                XORBIT, S.A
                <br />
                Attention: Copyright Agent
                <br />
                Los Verde, 101 Avenida Norte #517, San Salvador, El Salvador |
                Email: hello@XORBIT.com
              </Typography>
              <Typography>
                If you believe that any of your intellectual property rights
                other than copyrights have been infringed, please e-mail us at
                hello@XORBIT.com. We reserve the right, in our sole and absolute
                discretion, to suspend or terminate any user who infringes the
                intellectual property rights of XORBIT, S.A or others, and/or to
                remove, delete, edit or disable access to such person’s content.
                You agree that we have no liability for any action taken under
                this section.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                6. About Software in our Services
              </Typography>
              <Typography>
                The Services may enable you to access software running on our
                (or our vendors’) servers (collectively, “Software”). You agree
                that we retain the ownership of all rights, title, and interest
                in and to the Software.
              </Typography>
              <Typography>
                XORBIT, S.A, S.A gives you a personal, non-commercial,
                worldwide, royalty-free, non-assignable, and non-exclusive
                license to use the Software to access the Services. This license
                is for the sole purpose of enabling you to use and enjoy the
                benefit of the Services as provided by us, in the manner
                permitted by these Terms. You may not copy, modify, distribute,
                sell, or lease any part of our Services or Software, nor may you
                reverse engineer or attempt to extract the source code of the
                Services or Software, unless laws prohibit those restrictions or
                you have our written permission.
              </Typography>
              <Typography>
                There may be software programs contained within certain Software
                that have been licensed to us by third parties. The term
                “Software” as used herein shall refer to this third-party
                software except where the term “Software” is used in the context
                of our ownership. The same terms and conditions, S.S.A all
                limitations and restrictions, set forth in these Terms apply to
                each third-party software program contained in the Software. You
                acknowledge and agree that any third-party components are owned
                by their applicable licensors. We do not make any
                representations or warranties about the operation or
                availability of such third-party software. Neither we, nor our
                licensors, shall be liable for any unavailability or removal of
                such third-party software. We are not responsible for any
                communications to or from such licensors, or for the collection
                or use of information by such licensors. You consent to the
                communications enabled and/or performed by such third-party
                software, S.S.A automatic updating of the third-party software
                without further notice. You agree that such third-party software
                licensors are intended third-party beneficiaries under these
                Terms.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                7. Modifying and Terminating our Services
              </Typography>
              <Typography>
                We are constantly changing and improving our Services. We may
                add or remove functionalities or features, and we may suspend or
                stop a Service altogether, at any time, without any notice or
                liability.
              </Typography>
              <Typography>
                You can stop using our Services at any time, although we’ll be
                sorry to see you go. We may also stop providing Services to you,
                or add or create new limits to our Services, at any time.
              </Typography>
              <Typography>
                Sections 8 – 13 will survive termination or expiration of these
                Terms indefinitely
              </Typography>
              <Typography variant="subtitle2" my={1}>
                8. Our Warranties and Disclaimers
              </Typography>
              <Typography>
                OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS, NEITHER XORBIT,
                S.A NOR ITS LICENSORS, SUPPLIERS, ADVERTISERS, OR DISTRIBUTORS
                MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE
                DON’T MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE
                SERVICES, THE SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR
                RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE
                ALSO DO NOT MAKE ANY WARRANTIES OR COMMITMENT RELATING TO
                NONINFRINGEMENT, FREEDOM FROM VIRUSES OR OTHER HARMFUL CODE, OR
                ERROR-FREE OR UNINTERRUPTED OPERATIONS. WE PROVIDE THE SERVICES
                “AS-IS.”
              </Typography>
              <Typography>
                SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE
                IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE
                DISCLAIM ALL WARRANTIES.
              </Typography>
              <Typography>
                YOU AND YOUR HEIRS, SUCCESSORS, AND ASSIGNS HEREBY FOREVER
                IRREVOCABLY RELEASE, DISCHARGE, AND HOLD HARMLESS US, OUR
                AFFILIATES, AND OUR AND THEIR SUCCESSORS AND ASSIGNS, AND ITS
                AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS
                (COLLECTIVELY, “RELEASED PARTIES”) FROM, AND AGREE NOT TO SUE
                ANY RELEASED PARTY FOR, ANY LIABILITIES, CLAIMS, OBLIGATIONS,
                SUITS, ACTIONS, DEMANDS, EXPENSES, AND DAMAGES WHATSOEVER
                (COLLECTIVELY, “LIABILITIES”) THAT YOU MAY HAVE AGAINST ANY
                RELEASED PARTY WHETHER EXISTING NOW OR IN THE FUTURE, WHETHER
                KNOWN OR UNKNOWN, ARISING OUT OF OR IN CONNECTION WITH YOUR OR A
                THIRD PARTY’S CONDUCT RELATED TO USE OF THE SERVICES. YOU
                UNDERSTAND AND ACKNOWLEDGE THAT THE FOREGOING SENTENCE RELEASES
                AND DISCHARGES ALL LIABILITIES, WHETHER OR NOT THEY ARE
                CURRENTLY KNOWN TO YOU, AND YOU WAIVE YOUR RIGHTS UNDER EL
                SALAVADOR CIVIL CODE SECTION 1542. YOU UNDERSTAND THE MEANING OF
                EL SALVADOR CIVIL CODE SECTION 1542, WHICH READS AS FOLLOWS: “A
                GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
                DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF
                EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE
                MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.” BY AGREEING
                TO THESE TERMS AND THIS WAIVER, YOU ASSUME ALL RISK ARISING FROM
                YET UNKNOWN CLAIMS.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                9. Liability for our Services
              </Typography>
              <Typography>
                TO THE EXTENT NOT PROHIBITED BY LAW, XORBIT, S.A (AND ITS
                OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS) AND OUR LICENSORS,
                SUPPLIERS, ADVERTISERS, AND DISTRIBUTORS, WILL NOT BE
                RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, Crypto LOSSES
                OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE
                DAMAGES.
              </Typography>
              <Typography>
                TO THE EXTENT NOT PROHIBITED BY LAW, THE TOTAL LIABILITY OF
                XORBIT, S.A (AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS)
                AND OUR LICENSORS, SUPPLIERS, ADVERTISERS, AND DISTRIBUTORS, FOR
                ANY AND ALL CLAIMS UNDER THESE TERMS OR RELATING TO YOUR USE OF
                THE SERVICES, S.S.A FOR ANY IMPLIED WARRANTIES, IS LIMITED TO
                THE AMOUNT YOU PAID US TO USE THE SERVICES (OR, IF WE CHOOSE, TO
                SUPPLY YOU THE SERVICES AGAIN)
              </Typography>
              <Typography variant="subtitle2" my={1}>
                10. Business/Employer Uses of our Services
              </Typography>
              <Typography>
                If you are using our Services on behalf of a business or
                employer, you are accepting these Terms on their behalf, and
                that business or employer agrees to be bound by these Terms
              </Typography>
              <Typography variant="subtitle2" my={1}>
                11. Indemnification
              </Typography>
              <Typography>
                You hereby agree to indemnify, defend, and hold harmless XORBIT,
                S.A, its affiliated companies, and its and their predecessors,
                successors, and assigns, and its and their respective directors,
                officers, employees, agents, representatives, partners, and
                contractors from and against all claims, losses, expenses,
                damages and costs (S.S.A, but not limited to, reasonable
                attorneys’ fees), resulting from or arising out of your actual
                or alleged breach of these Terms, any content you provide
                through the Services, or your use or misuse of the Services.
                However, you will not be responsible for claims, damages, and
                costs which are found by a court of competent jurisdiction to
                have arisen solely from our violation of applicable law.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                12. About these Terms
              </Typography>
              <Typography>
                We may modify these Terms or any additional terms that apply to
                a Service for any reason, for example, to reflect changes to the
                law or changes to our Services. You should look at the Terms
                regularly and the “Last Updated” date at the beginning of these
                Terms. We’ll use reasonable efforts to give you notice of these
                modifications, such as posting notice of modifications to these
                Terms on this web page, through the Services, or via email. By
                continuing to use the Services after we make these
                modifications, you agree that you will be subject to the
                modified Terms. If you do not agree to the modified terms for a
                Service, you should discontinue your use of that Service.
              </Typography>
              <Typography>
                If there is a conflict between these Terms and any additional
                terms for a Service, the additional terms will control for that
                conflict
              </Typography>
              <Typography>
                These Terms control the relationship between XORBIT, S.A and
                you. They do not create any thirdparty beneficiary rights
                (except as set forth in Section 6). If you do not comply with
                these Terms, and we don’t take action right away, this doesn’t
                mean that we are giving up any rights that we may have (such as
                taking action in the future). If it turns out that a particular
                term is not enforceable, this will not affect any other terms.
              </Typography>
              <Typography>
                The laws of the United States and the State of El Salvador,
                excluding El Salvador’s conflict of laws rules, will apply to
                any disputes arising out of or relating to these terms or the
                Services You may not assign or delegate your rights or
                obligations relating to these terms or your account for the
                Services without our prior written consent. We may assign these
                terms or assign or delegate any of our rights or obligations at
                any time
              </Typography>
              <Typography>
                For information about how to contact XORBIT, S.A, please visit
                our contact page.
              </Typography>
              <Typography variant="subtitle2" my={1}>
                13. Binding Arbitration
              </Typography>
              <Typography>
                Without limiting your waiver and release in Section 8, you agree
                to the following:
              </Typography>
              <ul style={{ padding: "1rem" }}>
                <li style={{ listStyle: "number" }}>
                  <b>Purpose.</b> Any and all Disputes (as defined below)
                  involving you and XORBIT, S.A will be resolved through
                  individual arbitration. In arbitration, there is no judge or
                  jury and there is less discovery and appellate review than in
                  court. This Section 13 (the “Arbitration Provision”) shall be
                  broadly interpreted. Notwithstanding anything to the contrary
                  in these Terms, this Section 13 does not apply to an action by
                  either party to enjoin the infringement or misuse of its
                  intellectual property rights, S.S.A copyright, trademark,
                  patent or trade secret rights.
                </li>
                <li style={{ listStyle: "number" }}>
                  <b>Definitions.</b> The term “Dispute” means any claim or
                  controversy related to the Services or the Software, S.S.A but
                  not limited to any and all: (1) claims for relief and theories
                  of liability, whether based in contract, tort, fraud,
                  negligence, statute, regulation, ordinance, or otherwise; (2)
                  claims that arose before these Terms or any prior agreement;
                  (3) claims that arise after the expiration or termination of
                  these Terms; and (4) claims that are currently the subject of
                  purported class action litigation in which you are not a
                  member of a certified class. As used in this Arbitration
                  Provision, “XORBIT, S.A” means XORBIT, S.A and any of its
                  predecessors, successors, assigns, parents, subsidiaries and
                  affiliated companies and each of their respective officers,
                  directors, employees and agents, and “you” means you and any
                  users or beneficiaries of your access to the Services or the
                  Software.
                </li>
                <Typography my={2}>
                  Initiation of Arbitration Proceeding/Selection of Arbitrator.
                  The party initiating the arbitration proceeding may open a
                  case with JAMS, formerly Judicial Arbitration and Mediation
                  Services, S.A., (“JAMS”). You may deliver any required or
                  desired notice to XORBIT, S.A by mail to Los Verde, 101
                  Avenida Norte #517, San Salvador, El Salvador
                </Typography>
                <li style={{ listStyle: "number" }}>
                  <b>Right to Sue in Small Claims Court.</b> Notwithstanding
                  anything in this Arbitration Provision to the contrary, either
                  you or XORBIT, S.A may bring an individual action in a small
                  claims court in the area where you access the Services if the
                  claim is not aggregated with the claim of any other person and
                  if the amount in controversy is properly within the
                  jurisdiction of the small claims court.
                </li>
                <li style={{ listStyle: "number" }}>
                  <b>Arbitration Procedures.</b> This Arbitration Provision
                  shall be governed by the Federal Arbitration Act. Arbitrations
                  shall be administered by JAMS pursuant to its Streamlined
                  Arbitration Rules and Procedures (the “JAMS Rules”) as
                  modified by the version of this Arbitration Provision that is
                  in effect when you notify XORBIT, S.A about your Dispute. You
                  can obtain the JAMS Rules from the JAMS. If there is a
                  conflict between this Arbitration Provision and the rest of
                  these Terms, this Arbitration Provision shall govern. If there
                  is a conflict between this Arbitration Provision and the JAMS
                  rules, this Arbitration Provision shall govern. If JAMS will
                  not administer a proceeding under this Arbitration Provision
                  as written, the parties shall agree on a substitute
                  arbitration organization. If the parties cannot agree, the
                  parties shall mutually petition a court of appropriate
                  jurisdiction to appoint an arbitration organization that will
                  administer a proceeding under this Arbitration Provision as
                  written applying the JAMS Rules. A single arbitrator will
                  resolve the Dispute. Unless you and XORBIT, S.A agree
                  otherwise, any arbitration hearing will take place in, EL
                  SALVADOR. The arbitrator will honour claims of privilege
                  recognized by law and will take reasonable steps to protect
                  customer account information and other confidential or
                  proprietary information. The arbitrator shall issue a reasoned
                  written decision that explains the arbitrator’s essential
                  findings and conclusions. The arbitrator’s award may be
                  entered in any court having jurisdiction over the parties only
                  if necessary for purposes of enforcing the arbitrator’s award.
                  An arbitrator’s award that has been fully satisfied shall not
                  be entered in any court.
                </li>
                <li style={{ listStyle: "number" }}>
                  <b>Waiver of Class Actions and Collective Relief.</b>
                  THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO BE
                  ARBITRATED OR LITIGATED ON A CLASS ACTION, JOINT OR
                  CONSOLIDATED BASIS OR ON BASES INVOLVING CLAIMS BROUGHT IN A
                  PURPORTED REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL
                  PUBLIC (SUCH AS A PRIVATE ATTORNEY GENERAL), OTHER
                  SUBSCRIBERS, OR OTHER PERSONS. THE ARBITRATOR MAY AWARD RELIEF
                  ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY
                  TO THE EXTENT NECESSARY TO PROVIDE RELIEF WARRANTED BY THAT
                  INDIVIDUAL PARTY’S CLAIM. THE ARBITRATOR MAY NOT CONSOLIDATE
                  MORE THAN ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE
                  OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING.
                </li>
                <li style={{ listStyle: "number" }}>
                  <b> Arbitration Fees and Costs.</b>
                  If your claim seeks more than $75,000 in the aggregate, the
                  payment of the JAMS’s fees and costs will be governed by the
                  JAMS Rules. If your claims seek less than $75,000 in the
                  aggregate, the payment of the JAMS’s fees and costs will be
                  XORBIT, S.A’s responsibility. However, if the arbitrator finds
                  that your Dispute was frivolous or brought for an improper
                  purpose (as measured by the standards set forth in Federal
                  Rule of Civil Procedure 11(b)), the payment of the JAMS’s fees
                  and costs shall be governed by the JAMS Rules and you shall
                  reimburse XORBIT, S.A for all fees and costs that were your
                  obligation to pay under the JAMS Rules. You may hire an
                  attorney to represent you in arbitration. You are responsible
                  for your attorneys’ fees and additional costs and may only
                  recover your attorneys’ fees and costs in the arbitration to
                  the extent that you could in court if the arbitration is
                  decided in your favour. Notwithstanding anything in this
                  Arbitration Provision to the contrary, XORBIT, S.A will pay
                  all fees and costs that it is required by law to pay.
                </li>
                <li style={{ listStyle: "number" }}>
                  <b>Severability and Waiver of Jury Trial.</b>
                  If any part of subsection (f) of this Arbitration Provision is
                  found to be illegal or unenforceable, the entire Arbitration
                  provision will be unenforceable and the Dispute will be
                  decided by a court. WHETHER IN COURT OR IN ARBITRATION, YOU
                  AND XORBIT, S.A AGREE TO WAIVE THE RIGHT TO A TRIAL BY JURY TO
                  THE FULLEST EXTENT ALLOWED BY LAW. If any other clause in this
                  Arbitration Provision is found to be illegal or unenforceable,
                  that clause will be severed from this Arbitration Provision
                  and the remainder of this Arbitration Provision will be given
                  full force and effect.
                </li>
                <li style={{ listStyle: "number" }}>
                  <b>Continuation.</b> This Arbitration Provision will survive
                  the termination or expiration of these Terms.
                </li>
              </ul>
              <Typography mt={2} mb={1}>
                Advertiser Disclosure
              </Typography>
              <Divider />
              <Typography mt={1}>
                Product name, logo, brands, and other trademarks featured or
                referred to within XORBIT.com are the property of their
                respective trademark holders. This site may be compensated
                through third party advertisers. The offers that may appear on
                XORBIT.com website is from companies from which XORBIT.com may
                receive compensation. This compensation may influence the
                selection, appearance, and order of appearance of the offers
                listed on the website. However, this compensation also
                facilitates the provision by XORBIT.com of certain services to
                you at no charge. The website does not S.Alude all Crypto
                services companies or all of their available product and service
                offerings.
              </Typography>
            </div>
            <div id="cookies">
              <Typography
                variant="subtitle1"
                textAlign="center"
                sx={{ mb: 1, color: "#fff" }}
              >
                Cookies Policy
              </Typography>
              <Typography>
                At Crypto, we respect your concerns about privacy and value the
                relationship that we have with you.
              </Typography>
              <Typography>
                Like many companies, we use technology on our website to collect
                information that helps us enhance your experience and our
                products and services. The cookies that we use at Crypto allow
                our website to work and help us to understand what information
                and advertising is most useful to visitors.
              </Typography>
              <Typography>
                Please take a moment to familiarize yourself with our cookie
                practices and let us know if you have any questions by sending
                us an e-mail or submitting a request through the “Contact Us”
                form on our websites.
              </Typography>
              <Typography my={1} sx={{ color: "#06c" }}>
                Who is collecting it?
              </Typography>
              <Typography>
                Any personal data provided to or collected by Crypto via cookies
                and other tracking technologies is controlled by both Crypto PLC
                and Crypto N.V., which are the joint parent companies of the
                Crypto Group (all Crypto Group companies, together the data
                controllers).
              </Typography>
              <Typography>
                By using our websites, you are consenting to our use of cookies
                in accordance with this Cookie Notice and our Privacy Notice.
              </Typography>
              <Typography>
                This Cookie Notice applies to any websites, apps, branded pages
                on third-party platforms (such as Facebook or YouTube), and
                applications accessed or used through such websites or
                third-party platforms (hereinafter, “our websites”) which are
                operated by or on behalf of Crypto.
              </Typography>
              <Typography>
                By using our websites, you are consenting to our use of cookies
                in accordance with this Cookie Notice and our Privacy Notice. If
                you do not agree to our use of cookies in this way, you should
                set your browser settings accordingly, disable the cookies that
                we use or not use our websites at all. If you disable the
                cookies we use, this may impact your user experience while on
                the websites.
              </Typography>
              <Typography>
                The section below summarizes the different types of cookies we
                use on our websites, together with their respective purpose and
                provides you with the ability to manage these cookies
              </Typography>
              <Typography>
                Crypto will only collect, use or disclose your personal data
                where it is fair and lawful to do so.
              </Typography>
              <Typography>
                For a more detailed understanding of how we use personal data
                collected by Crypto cookies, please refer to our Privacy Notice.
              </Typography>
              <Typography my={1} sx={{ color: "#06c" }}>
                What does cookie mean?
              </Typography>
              <Typography>
                Cookies, pixel tags and similar technologies (collectively
                ‘cookies’) are files containing small amounts of information
                which are downloaded to any internet enabled device – such as
                your computer, smart phone or tablet – when you visit a website.
              </Typography>
              <Typography>
                Cookies, pixel tags and similar technologies (collectively
                ‘cookies’) are files containing small amounts of information
                which are downloaded to any internet enabled device – such as
                your computer, smart phone or tablet – when you visit a website.
                Cookies are then sent back to the originating website on each
                subsequent visit, or to another website that recognizes that
                cookie. Cookies do lots of different and useful jobs, such as
                remembering your preferences, generally improving your online
                experience, and helping us to offer you the best product and
                services.
              </Typography>
              <Typography>
                There are many types of cookies. They all work in the same way,
                but have minor differences. For a detailed list of cookies used
                on our websites, please refer to the below relevant section.
              </Typography>
              <Typography my={1} sx={{ color: "#06c" }}>
                What purpose do we use cookies for?
              </Typography>
              <Typography>
                We use cookies to make Crypto websites easier to use, to deliver
                a personalized experience on our websites, and to better tailor
                our products, services and websites to your interests and needs.
                Cookies are used to help speed up your future activities and
                your experience on Crypto websites.
              </Typography>
              <Typography>
                We use cookies to make Crypto websites easier to use, to deliver
                a personalized experience on our websites, and to better tailor
                our products, services and websites to your interests and
                needs.Cookies are used to help speed up your future activities
                and your experience on Crypto websites
              </Typography>
              <Typography>
                The cookies you consent to, are also used to collect your
                personal data which we then profile into audiences so that we
                can deliver targeted advertising tailored to your interests and
                limit the number of times you see an advertisement. For more
                detailed information about the profiling activities Crypto
                undertakes with your personal data for advertising, please see
                our Privacy Notice.
              </Typography>
              <Typography>
                We also insert cookies in emails and newsletters to improve our
                content and advertising.
              </Typography>
              <Typography>
                Lastly, we use cookies to compile anonymous, aggregated
                statistics that allow us to understand how people use our
                websites and to help us improve their structure and content and
                also help us measure the effectiveness of advertising campaigns
                on Crypto and non-Crypto websites
              </Typography>
              <Typography>
                More detailed information about the types of cookies we use and
                for what purposes, can be found in the below relevant section.
              </Typography>
              <Typography my={1} sx={{ color: "#06c" }}>
                How can I control or delete cookies?
              </Typography>
              <Typography>
                There are many ways to manage your cookies:
              </Typography>
              <ul style={{ padding: "1rem" }}>
                <li style={{ listStyle: "disc" }}>
                  You can refuse your consent;
                </li>
                <li style={{ listStyle: "disc" }}>
                  You can disable Crypto or third-party cookies by use of your
                  browser settings (see how here)
                </li>
                <li style={{ listStyle: "disc" }}>
                  You can use our cookie management tool to disable Crypto or
                  third-party cookies (see how here). There are many ways to
                  manage your cookies
                </li>
                <li style={{ listStyle: "disc" }}>
                  You can refuse your consent
                </li>
                <li style={{ listStyle: "disc" }}>
                  You can disable Crypto or third-party cookies by use of your
                  browser settings (see how here)
                </li>
                <li style={{ listStyle: "disc" }}>
                  You can use our cookie management tool to disable Crypto or
                  third-party cookies (see how here)
                </li>
                <li style={{ listStyle: "disc" }}>sdfgh</li>
              </ul>
              <Typography my={1} sx={{ color: "#06c" }}>
                Control via your browser settings
              </Typography>
              <Typography>
                Most internet browsers are initially set up to automatically
                accept cookies. If you do not want our websites to store cookies
                on your device, you can change your browser settings so that you
                receive a warning before certain cookies are stored. You can
                also adjust your settings so that your browser refuses most of
                our cookies or only certain cookies from third parties. You can
                also withdraw your consent to cookies by deleting the cookies
                that have already been stored.
              </Typography>
              <Typography>
                If you disable the cookies that we use, this may impact your
                experience while on the Crypto website, for example you may not
                be able to visit certain areas of a website or you may not
                receive personalized information when you visit a website.
              </Typography>
              <Typography>
                If you use different devices to view and access the Crypto
                website (e.g., your computer, smart phone, tablet) you will need
                to ensure that each browser on each device is adjusted to suit
                your cookie preferences
              </Typography>
              <Typography>
                The procedures for changing your settings and cookies differ
                from browser to browser. If necessary, use the help function on
                your browser or click on one of the links below to go directly
                to the user manual for your browser.
              </Typography>
              <ul style={{ padding: "1rem" }}>
                <li style={{ listStyle: "disc", color: "#ff14c8" }}>
                  Internet Explorer
                </li>
                <li style={{ listStyle: "disc", color: "#ff14c8" }}>
                  Mozilla Firefox
                </li>
                <li style={{ listStyle: "disc", color: "#ff14c8" }}>
                  Google Chrome
                </li>
                <li style={{ listStyle: "disc", color: "#ff14c8" }}>Safari</li>
                <li style={{ listStyle: "disc", color: "#ff14c8" }}>Opera</li>
              </ul>
              <Typography>
                There are also software products available that can manage
                cookies for you.
              </Typography>
              <Typography>
                You can also use www.ghostery.com to evaluate the use of cookies
                used on our websites. To find out more about cookies, S.S.A how
                to see what cookies have been set and how to manage and delete
                them, visit www.allaboutcookies.org.
              </Typography>
              <Typography my={1} sx={{ color: "#06c" }}>
                Control via our cookie management tool
              </Typography>
              <Typography>
                You can disable Crypto or third-party cookies by use of our
                cookie consent tool which can be found here.
              </Typography>
              <Typography>
                Switching off tracking cookies, for example, ensures that Crypto
                will no longer track your online behaviour. However please note
                that opting-out from tracking cookies does not necessarily mean
                that you will receive less Crypto advertising. It just means
                that the advertising you receive will not be tailored to your
                interests.
              </Typography>
              <Typography my={1} sx={{ color: "#06c" }}>
                What cookies do we use?
              </Typography>
              <Typography>
                The cookies used on Crypto websites may be generally categorized
                as follow:
              </Typography>
              <ul style={{ padding: "1rem" }}>
                <li style={{ listStyle: "disc" }}>
                  Necessary Cookies. These cookies do not identify you as an
                  individual.
                </li>
                <li style={{ listStyle: "disc" }}>
                  Performance Cookies. These cookies do not identify you as an
                  individual.
                </li>
                <li style={{ listStyle: "disc" }}>
                  Functionality Cookies. The information these cookies collect
                  may S.Alude personal data that you have disclosed.
                </li>
                <li style={{ listStyle: "disc" }}>
                  Targeting or Advertising Cookies. Most types of these cookies
                  track consumers via their Device ID or IP address therefore
                  they may collect personal data.
                </li>
                <li style={{ listStyle: "disc" }}>
                  Third-Party Cookies. Based on the type of cookies used by the
                  relevant third-party, the information these cookies collect
                  may S.Alude personal data. The cookies used on Crypto websites
                  may be generally categorized as follow:
                </li>
                <li style={{ listStyle: "disc" }}>
                  Necessary Cookies. These cookies are essential to make Crypto
                  websites work correctly, they enable you to move around our
                  websites and use our features. Without these cookies, services
                  like shopping baskets cannot be provided. Examples S.Alude
                  remembering previous actions (e.g., entered text) when
                  navigating back to a page in the same session.
                  <ul style={{ padding: "1rem" }}>
                    <li style={{ listStyle: "circle" }}>
                      Do these cookies collect personal data/identify me? These
                      cookies do not identify you as an individual. If you do
                      not accept these cookies, it may affect the performance of
                      the website, or parts of it.
                    </li>
                  </ul>
                </li>
                <li style={{ listStyle: "disc" }}>
                  Performance Cookies. These cookies collect information about
                  how you use our websites, for instance which pages you go to
                  most often, the time spent on our websites, and any issues
                  encountered, such as error messages. These cookies are also
                  used to let affiliates know if you came to one of our websites
                  from an affiliate and if your visit resulted in the use or
                  purchase of a product or service from us, S.S.A details of the
                  product or service purchased. This helps us improve the
                  performance of our websites
                  <ul style={{ padding: "1rem" }}>
                    <li style={{ listStyle: "circle" }}>
                      Do these cookies collect personal data/identify me? These
                      cookies do not identify you as an individual. All
                      information these cookies collect is aggregated and
                      therefore anonymous. It is only used to improve how a
                      website works
                    </li>
                  </ul>
                </li>
                <li style={{ listStyle: "disc" }}>
                  Functionality Cookies. These cookies allow our websites to
                  remember the choices you make (such as your user name,
                  language or the region you are in) to provide a more
                  personalized online experience. These cookies may also be used
                  to remember changes you have made to text size, fonts and
                  other parts of web pages that you can customize. Similarly,
                  they may be used to keep track of what featured products or
                  videos have been viewed to avoid repetition, and to enable you
                  to play games and engage with social tools, such as blogs,
                  chat rooms and forums
                  <ul style={{ padding: "1rem" }}>
                    <li style={{ listStyle: "circle" }}>
                      Do these cookies collect personal data/identify me? The
                      information these cookies collect may S.Alude personal
                      data that you have disclosed. If you do not accept these
                      cookies, it may affect the performance and functionality
                      of the website and may restrict access to content on the
                      website
                    </li>
                  </ul>
                </li>
                <li style={{ listStyle: "disc" }}>
                  Targeting or Advertising Cookies. These cookies are used to
                  deliver content that is more relevant to you and your
                  interests. They are also used to deliver targeted advertising
                  or limit the number of times you see an advertisement as well
                  as help measure the effectiveness of the advertising campaigns
                  on Crypto and non-Crypto websites. They remember that you have
                  visited one of our websites and this information is shared
                  with other parties, S.S.A advertisers and our agencies. These
                  cookies may also be linked to site functionality provided by
                  third-parties.
                  <ul style={{ padding: "1rem" }}>
                    <li style={{ listStyle: "circle" }}>
                      Do these cookies collect personal data/identify me? Most
                      types of these cookies track consumers via their Device ID
                      or IP address therefore they may collect personal data.
                    </li>
                  </ul>
                </li>
                <li style={{ listStyle: "disc" }}>
                  Third-Party Cookies. We use a number of partners that may also
                  set cookies on your device on our behalf when you visit our
                  websites to allow them to deliver tailored Crypto advertising
                  within their domains, for example Facebook and Google Double
                  Click. We endeavour to identify these cookies before they are
                  used so that you can decide whether you wish to accept them or
                  not. We also use a number of partners to provide digital
                  experiences and functionalities on our websites. For example,
                  while browsing Crypto websites you may be served cookies from
                  third-parties who provide some of its features on our websites
                  (e.g., a YouTube video), although you have withdrawn or
                  declined your consent to our cookies. This happens because you
                  have directly given your consent to the use of their cookies.
                  In such cases, you should directly withdraw your consent on
                  the relevant third-party website.
                  <ul style={{ padding: "1rem" }}>
                    <li style={{ listStyle: "circle" }}>
                      Do these cookies collect personal data/identify me? Based
                      on the type of cookies used by the relevant third-party,
                      the information these cookies collect may S.Alude personal
                      data
                    </li>
                  </ul>
                </li>
              </ul>
              <Typography my={1} sx={{ color: "#06c" }}>
                Duration of the cookie we use
              </Typography>
              <Typography>
                In terms of duration, we may use two different types of cookies
                on our websites:
              </Typography>
              <Typography>
                Session Cookies. These cookies are temporary cookies that remain
                on your device until you leave our websites
              </Typography>
              <Typography>
                Persistent Cookies. These cookies remain on your device for much
                longer or until you manually delete them (how long the cookie
                remains on your device will depend on the duration or “lifetime”
                of the specific cookie, as well as your browser settings, as
                stated below).
              </Typography>
            </div>
          */}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Policy;
