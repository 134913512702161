import { Box, Card, Stack, Typography } from "@mui/material";

const Message = () => (
  <Stack
    direction="row"
    alignItems="center"
    sx={{ mb: 4, mt: -1, width: { md: "340px" } }}
  >
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        display="flex"
        justifyContent="center"
        variant="h5"
        color={"black"}
        gutterBottom
      >
        Sign in to BITZSTOX
      </Typography>
      {/* <Typography sx={{ color: "text.secondary" }}>
      Lorem Ipsum is simply dummy...
    </Typography> */}
    </Box>
  </Stack>
);

export default Message;
