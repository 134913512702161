import { useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router";
import usePagination from "src/components/pagination/usePagination";
import axiosInstance from "src/utils/axios";
import { getUrl } from "../config";
import useErrors from "src/hooks/useErrors";
import serializeDate from "src/utils/serialize-date";

const useReport = (uriKey, { title, heading, filter }) => {
  const { setData } = useOutletContext();
  const [report, setReport] = useState([]);
  const { count, onChange, page, seed, rowStart } = usePagination();
  const handleErrors = useErrors();

  const getReport = async (page = 1, filter) => {
    const URI = `api/admin/${getUrl(uriKey)}`;
    try {
      const { data, status } = await axiosInstance(URI, {
        params: { page, ...filter },
      });

      if (status === 200) {
        const { last_page, from, data: list } = data?.data;
        seed(last_page, from);
        setReport(list);
      }
    } catch (err) {
      handleErrors(err);
    }
  };

  useMemo(() => {
    setData({ title, heading, type: uriKey });
  }, [title, heading, uriKey]);

  useEffect(() => {
    const { start_date, end_date, month, year } = filter;
    if (heading === "Bitzstox Reward Report") {
      getReport(page, {
        // ...filter,
      });
    } else {
      getReport(page, {
        ...filter,
        start_date: serializeDate(start_date),
        end_date: serializeDate(end_date),
      });
    }
  }, [page]);

  return { report, getReport, count, onChange, page, rowStart };
};

export default useReport;
