import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormProvider, RHFSelect } from "src/components/hook-form";
import EWalletPaymentTypes from "src/components/payment-types/e-wallet";
import useLocales from "src/hooks/useLocales";
import { date, object, ref } from "yup";
import CustomRHFDatePicker from "./customRHFDatePicker";
import useAuth from "src/hooks/useAuth";
import Users from "src/components/all-users";

export const incomeReportDefaultValues = {
  start_date: null,
  end_date: null,
  payment_type: "all",
  user_id: "",
};

const getTomorrow = () => {
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow;
};

const schema = object().shape({
  start_date: date()
    .max(getTomorrow(), "Future start date not allowed")
    .nullable(),
  end_date: date()
    .min(ref("start_date"), "end date can't be before start date")
    .nullable(),
});
const paymentTypes = [
  { label: "All", value: "all", name: "All" },

  {
    value: "stake_surrender",
    name: "Stake Surrender",
  },
  {
    value: "customer_incentive",
    name: "Customer Incentive",
  },

  {
    value: "partners_incentive",
    name: "Partners Incentive",
  },
  {
    value: "employee_incentive",
    name: "Employee Incentive",
  },
  {
    value: "partners_referral_bonus",
    name: "Partners Referral Bonus",
  },

  {
    value: "employee_referral_bonus",
    name: "Employee Referral Bonus",
  },
  {
    value: "bitz_club_reward",
    name: "Bitz Club Reward",
  },
  { label: "Transfer In", value: "fund_transfer", name: "Transfer In" },
];

const Filter = ({ setFilter }) => {
  const { user } = useAuth();

  const methods = useForm({
    defaultValues: incomeReportDefaultValues,
    resolver: yupResolver(schema),
  });
  const { translate } = useLocales();
  const onSubmit = (data) => {
    const { start_date, end_date, payment_type, poolName, user_id } = data;
    setFilter({
      start_date:
        start_date && new Date(start_date).toLocaleDateString("en-GB"),
      end_date: end_date && new Date(end_date).toLocaleDateString("en-GB"),
      payment_type: payment_type,
      user_id: user_id,
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1,1fr)",
            sm: "repeat(5, 1fr)",
          },
          rowGap: 3,
          columnGap: 3,
          marginBottom: "2rem",
        }}
      >
        <CustomRHFDatePicker
          label={translate("adminFinancial.payout.pickStartDate")}
          name="start_date"
          size="small"
        />
        <CustomRHFDatePicker
          label={translate("adminFinancial.payout.pickEndDate")}
          name="end_date"
          size="small"
        />
        <Users
          label={translate("adminFinancial.Ewallet.userName")}
          name="user_id"
          size="small"
        />
        <RHFSelect
          name="payment_type"
          label={translate("sourceType")}
          size="small"
        >
          {paymentTypes.map(({ value, label, name }) => (
            <option value={value}>{name}</option>
          ))}
        </RHFSelect>

        <Button type="submit" variant="contained" size="medium">
          {translate("userFinancial.eWallet.getReport")}
        </Button>
      </Box>
    </FormProvider>
  );
};

export default Filter;
