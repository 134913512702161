import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useAuth from "src/hooks/useAuth";
import useErrors from "src/hooks/useErrors";
import useSettings from "src/hooks/useSettings";
import axiosInstance from "src/utils/axios";
import Horizontal from "../components/horizontal";
import Vertical from "../components/vertical";
import navConfig from "./NavConfig";

const useSubAdminPermissions = () => {
  const navigate = useNavigate();
  const { isSubAdmin } = useAuth();
  const [config, setConfig] = useState([]);
  const { pathname } = useLocation();
  const activeSubAdminRoutes = useRef([]);
  const [activeList, setActiveList] = useState([]);
  const handleErrors = useErrors();
  const fetchRoutes = async () => {
    try {
      const { status, data } = await axiosInstance(
        "api/load-subadmin-permissions"
      );

      if (status === 200) {
        const navConfig = JSON.parse(
          data.data?.user_group?.sub_admin_group_permissions[0]
            ?.permission_string || "[]"
        );

        const mapper = (items) => {
          items.map((item) => {
            if (item.active) {
              activeSubAdminRoutes.current.push(item.path);
            }
            if (item.children) {
              mapper(item.children);
            }
          });
        };

        navConfig.map(({ items }) => {
          mapper(items);
        });
        setActiveList(activeSubAdminRoutes.current);
        setConfig(navConfig);
      }
    } catch (err) {
      handleErrors(err);
    }
  };
  useEffect(() => {
    if (isSubAdmin) fetchRoutes();
  }, [isSubAdmin]);

  useMemo(() => {
    setConfig(navConfig);
  }, [navConfig]);

  return config;
};

const AdminLayout = () => {
  const { themeLayout } = useSettings();
  const config = useSubAdminPermissions();

  const verticalLayout = themeLayout === "vertical";

  return verticalLayout ? (
    <Vertical navConfig={config} />
  ) : (
    <Horizontal navConfig={config} />
  );
};

export default AdminLayout;
