import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Ewallet = Loadable(lazy(() => import("src/pages/financial/ewallet")));
const DepositWallet = Loadable(
  lazy(() => import("src/pages/financial/deposit"))
);
const FundCredits = Loadable(
  lazy(() => import("src/pages/financial/fundCredit/index"))
);

// const Payout = Loadable(
//   lazy(() => import("src/pages/financial/payout/payout"))
// );

const Payout = Loadable(
  lazy(() => import("src/pages/financial/payout/payout/index"))
);

const PendingPayout = Loadable(
  lazy(() =>
    import("src/pages/financial/payout/payout/components/pending/index")
  )
);

const PayoutRequest = Loadable(
  lazy(() =>
    import("src/pages/financial/payout/payout/components/request/index")
  )
);

const PayoutHistory = Loadable(
  lazy(() =>
    import("src/pages/financial/payout/payout/components/history/index")
  )
);

const FinancialReport = Loadable(
  lazy(() => import("src/pages/financial/financialReport/financialReport"))
);

const OrderApproval = Loadable(
  lazy(() => import("src/pages/orderApproval/index"))
);

const financial = [
  {
    path: "financial",
    children: [
      { element: <Navigate to="e-wallet" />, index: true },
      { path: "e-wallet", element: <Ewallet /> },
      { path: "deposit-wallet", element: <DepositWallet /> },
      { path: "fund-credits", element: <FundCredits /> },
      {
        path: "payout",
        element: <Payout />,
        children: [
          { index: true, element: <Navigate to="request" replace /> },
          { path: "request", element: <PayoutRequest /> },
          { path: "pending", element: <PendingPayout /> },
          { path: "history", element: <PayoutHistory /> },
        ],
      },
      // { path: "payout", element: <Payout /> },

      { path: "report", element: <FinancialReport /> },
      { path: "order_approval", element: <OrderApproval /> },
    ],
  },
];

export default financial;
