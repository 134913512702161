// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          height: 48,
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          backgroundImage: "linear-gradient(to right, #285dac, #49b1e3)", // Add gradient here
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        // containedPrimary: {
        //   boxShadow: theme.customShadows.primary,
        //   backgroundImage: "linear-gradient(to right, #285dac, #49b1e3)", // Add gradient here
        // },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
          backgroundImage: "linear-gradient(to right, #285dac, #49b1e3)", // Add gradient here
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
          backgroundImage: "linear-gradient(to right, #285dac, #49b1e3)", // Add gradient here
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
          backgroundImage: "linear-gradient(to right, #285dac, #49b1e3)", // Add gradient here
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
          backgroundImage: "linear-gradient(to right, #285dac, #49b1e3)", // Add gradient here
        },
        containedError: {
          boxShadow: theme.customShadows.error,
          backgroundImage: "linear-gradient(to right, #285dac, #49b1e3)", // Add gradient here
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
