import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import warning from "src/images/warning.png";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import axiosInstance from "src/utils/axios";

import { useSnackbar } from "notistack";
import Iconify from "src/components/Iconify";
import Ternary from "src/components/ternary";
import useQueryParams from "src/hooks/useQueryParams";

const UpdateDialog = ({ fetchTicket }) => {
  const { queryObject, deleteParam } = useQueryParams();
  const { id } = useParams();
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { update_to, name: statusName } = queryObject;

  const openDialog = Boolean(update_to);

  const updateStatus = async () => {
    const reqData = new FormData();
    reqData.append("_method", "PUT");
    reqData.append("status", update_to);
    try {
      const { status, data } = await axiosInstance.post(
        `/api/admin/support-ticket-status-change/${id}`,
        reqData
      );
      if (status === 200) {
        onCloseDialogue();
        enqueueSnackbar(data.message);
        fetchTicket();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCloseDialogue = () => {
    deleteParam("name", "update_to");
  };

  const { breakpoints } = useTheme();
  const u_sm = useMediaQuery(breakpoints.up("sm"));

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openDialog}
      onClose={onCloseDialogue}
      aria-labelledby="delete-Subscription"
    >
      <DialogTitle
        id="delete-Subscription"
        sx={{ textAlign: "-webkit-center" }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Change status?</Typography>
          <IconButton onClick={onCloseDialogue}>
            <Iconify
              icon="ep:close-bold"
              sx={{ color: palette.warning.normal }}
            />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={warning} width="100px" />
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          width="100%"
        >
          
          <Ternary
            when={u_sm}
            then={
              <Button
                onClick={onCloseDialogue}
                sx={{ color: palette.warning.normal }}
              >
                Close
              </Button>
            }
          />
          <Button variant="contained" type="submit" onClick={updateStatus}>
            Yes, change status to {statusName}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDialog;
