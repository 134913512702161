import { Alert } from "@mui/material";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import { setSession } from "src/utils/jwt";

const ImpersonationBanner = () => {
  const { translate } = useLocales();
  const { user } = useAuth();
  const isImpersonate = localStorage.getItem("isImpersonate");
  const goBackToAdmin = () => {
    const adminToken = localStorage.getItem("adminToken");
    localStorage.setItem("isAdmin", true);
    localStorage.removeItem("isSubAdmin");
    localStorage.removeItem("isImpersonate");
    setSession(adminToken);
    window.location = `${window.origin}${sessionStorage.getItem(
      "impersonationSource"
    )}`;
  };
  return isImpersonate ? (
    <Alert sx={{ mb: 2.5 }} severity="info">
      {translate("impoersonation.heads")} {user.username}{" "}
      <strong
        onClick={goBackToAdmin}
        style={{
          textDecoration: "none",
          cursor: "pointer",
        }}
      >
        {translate("impoersonation.click")}
      </strong>{" "}
      ,{translate("impoersonation.toGo")}
    </Alert>
  ) : null;
};

export default ImpersonationBanner;
