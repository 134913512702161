import { Box, Card, Container, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoginForm } from "src/sections/auth/login";
import Message from "./Message";
import NoAccountSection from "./NoAccountSection";
import Iconify from "src/components/Iconify";

const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(8, 2, 6, 2),
  marginTop: "-15px",
}));

const MainSection = ({ handleCloseLogin, handleClickOpenRegister }) => {
  return (
    <>
      <Box sx={{ textAlign: "right" }}>
        <Tooltip title="Close">
          <IconButton
            sx={{
              position: "absolute",
              right: "-10px",
              backgroundColor: "#168E91 !important",
              borderRadius: "50%",
              top: "-25px",
              color: "white",
              right: { md: "-15px", sm: "15px", xs: "15px" },
              top: { md: "-15px", sm: "15px", xs: "15px" },
            }}
            aria-label="close"
            onClick={handleCloseLogin}
            color="error"
          >
            <Iconify icon="material-symbols:close" sx={{ color: "#fff" }} />
          </IconButton>
        </Tooltip>
      </Box>

      <ContentStyle>
        <LoginForm
          handleClickOpenRegister={handleClickOpenRegister}
          handleCloseLogin={handleCloseLogin}
        />
      </ContentStyle>
    </>
  );
};

export default MainSection;
