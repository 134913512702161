import { useRoutes } from "react-router-dom";

import GoogleLogin from "src/pages/auth/googleAuth";
import PDFViewer from "../components/pdfViewer";
import admin from "./admin";
import auth from "./auth";
import main from "./main";
import user from "./user";

const Router = () => {
  return useRoutes([
    ...auth,
    {
      path: "OAuth",
      children: [{ path: "g/:g_token", element: <GoogleLogin /> }],
    },
    ...user,
    ...admin,
    ...main,
    { path: "/pdf", element: <PDFViewer /> },
  ]);
};

export default Router;
