import { capitalCase } from "change-case";
import React from "react";
import { RHFSelect } from "src/components/hook-form";

const paymentTypes = [
  "all",
  "referral_bonus",
  // "binary_bonus",
  // "first_order_bonus",
  // "achievement_bonus",
  "level_bonus",
  "deducted_by_admin",
  "credited_by_admin ",
  "withdrawal_bonus",
];

const PaymentTypes = () => {
  return (
    <RHFSelect name="payment_type" size="small">
      {paymentTypes.map((type) => (
        <option value={type}>{capitalCase(type)}</option>
      ))}
    </RHFSelect>
  );
};

export default PaymentTypes;
