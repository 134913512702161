import React, { useEffect } from "react";
import "./style.css";

function TradingViewWidget() {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js";
    script.async = true;

    // Define your configuration as a separate object
    const config = {
      feedMode: "all_symbols",
      colorTheme: "dark",
      isTransparent: false,
      displayMode: "regular",
      width: "100%",
      height: "100%",
      locale: "in",
    };

    script.text = `
      {
        "feedMode": "${config.feedMode}",
        "colorTheme": "${config.colorTheme}",
        "isTransparent": ${config.isTransparent},
        "displayMode": "${config.displayMode}",
        "width": "${config.width}",
        "height": "${config.height}",
        "locale": "${config.locale}"
      }
    `;

    document.querySelector(".container").appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      id="tradingview-widget-container"
      className="tradingview-widget-container"
    >
      {/* <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        >
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div> */}
    </div>
  );
}

export default TradingViewWidget;
