import { Box, Card, Grid, Tab, Tabs } from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import useTabs from "src/hooks/useTabs";
import { PATH_USER } from "src/routes/paths";
import useFetchSummary from "../hooks/useFetchSummary";
import { DataList } from "./components";
import DirectorSummary from "./components/director-summary";
import DataListpayout from "./components/payout/dataTable";
import Summary from "./components/summary";

const Index = () => {
  const { translate } = useLocales();
  // const { data, ...rest } = useFetchWitPagination("ewallet");
  const { summary } = useFetchSummary("ewallet-data");

  const { currentTab, onChangeTab } = useTabs("ewllet");
  const { user } = useAuth();

  const PAYOUT_TABS = [
    {
      value: "ewllet",
      text: translate("revenueReport"),
      component: <DataList />,
    },
    {
      value: "payout",
      text: translate("withdrawalReport"),
      component: <DataListpayout />,
    },
  ];

  return (
    <div>
      <Page title={translate("Finance Report")}>
        <Box sx={{ p: 2 }}>
          <HeaderBreadcrumbs
            heading={translate("Finance Report")}
            links={[
              { name: translate("dashboard"), href: PATH_USER.root },
              { name: translate("Finance Report") },
            ]}
          />

          <Grid container spacing={3}>
            <Summary summary={summary} />
          </Grid>

          <Card sx={{ p: 3, mt: 3 }}>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {PAYOUT_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={tab.text}
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>
            <Box sx={{ mb: 2 }} />
            {PAYOUT_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
          </Card>
        </Box>
      </Page>
    </div>
  );
};

export default Index;
