import { Box, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import Footer from "src/components/root_page/layout/footer.js";
import Header from "src/components/root_page/layout/header.js";
const Index = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="MobileOptimized" content="width" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="title" content="BITZSTOX | The future of Finance" />
        <title>BITZSTOX | The future of Finance</title>
        <link
          rel="icon"
          href={localStorage.getItem("favicon") || "public/favicon/favicon.ico"}
          type="image/x-icon"
        />
        <meta
          name="keywords"
          content="If crypto succeeds, it's not because it empowers better people. It's because it empowers better institutions."
        />
        <meta name="author" content="BITZSTOX" />
        <meta
          name="description"
          content="If crypto succeeds, it's not because it empowers better people. It's because it empowers better institutions."
        />
        <link href="assets/css/style.css" rel="stylesheet" type="text/css" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <body
        className="banner"
        style={{ fontFamily: "'Montserrat', sans-serif" }}
      >
        <div>
          <Header />
          {/* <!-- *************nav end **************** --> */}
          <section class="teamSection">
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <h1 style={{ fontWeight: 900, fontSize: "4rem" }}>
                Coming Soon...
              </h1>
              <Typography variant="h6">
                Currently this service is not available in your region
              </Typography>
            </Box>
          </section>
          <Footer />
        </div>
      </body>
    </>
  );
};

export default Index;
