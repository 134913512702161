import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import users from "src/images/loginUser.png";
import bitz from "src/images/bitzstox@4x.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import newLogo from "src/images/bitzstox@4x.png";
import MainSection from "src/pages/auth/Login/components/MainSection/index.jsx";
import Register from "src/pages/auth/Register";
import Emailmessage from "src/sections/auth/register/emailMessage";
import axiosInstance from "src/utils/axios";
import "./style.css";
const Header = () => {
  const { user, logout, isAdmin } = useAuth();
  const { uname } = useParams();
  const isLoggedIn = !user;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openRegister, setOpenRegister] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);

  const handleClickOpenRegister = () => {
    setOpenRegister(true);
    handleCloseLogin(true);
  };
  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const handleClickOpenLogin = () => {
    setOpenLogin(true);
    handleCloseRegister(true);
  };
  const handleCloseLogin = () => {
    setOpenLogin(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openMessage, setOpenMessage] = useState();

  const handleClickOpenMessage = () => {
    setOpenMessage(true);
  };
  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const [logo, setLogo] = useState(() => localStorage.getItem("logo") || "");

  const fetchBrandSettings = async () => {
    try {
      const { data, status } = await axiosInstance("api/brand-details");
      if (status === 200) {
        const { logo, side_bar_logo, favicon } = data.data;

        if (logo) {
          localStorage.setItem("logo", logo);
          localStorage.setItem("side_bar_logo", side_bar_logo);
          localStorage.setItem("favicon", favicon);
          setLogo(logo);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!logo) fetchBrandSettings();
  }, [logo]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setOpenDrawer(false);
    }
  }, [isMobile]);
  const buttonStyle = {
    background: "linear-gradient(to right, #285bab, #49b1e3)",
    border: 0,
    padding: "8px 15px",
    color: "#fff",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "13px",
    fontWeight: "bold",
    outline: "none",
    marginBottom: "10px",
  };
  const [bitzClubAnchorEl, setBitzClubAnchorEl] = useState(null);

  const handleBitzClubClick = (event) => {
    setBitzClubAnchorEl(event.currentTarget);
  };

  const handleBitzClubClose = () => {
    setBitzClubAnchorEl(null);
  };
  return (
    <div className="header">
      {isMobile ? (
        // <Toolbar>
        <AppBar
          position="fixed"
          sx={{
            p: 2,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#9c9c9c",
          }}
        >
          <img src={bitz} style={{ width: "150px" }} />

          <IconButton
            aria-expanded={open ? "true" : undefined}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
            sx={{ width: "50px" }}
          >
            <MenuIcon />
          </IconButton>
        </AppBar>
      ) : (
        // </Toolbar>
        <nav className="navlanding" style={{ backgroundColor: "#f7f7f7" }}>
          <div class="containeroxNew">
            <div>
              <a href="/">
                <img width={200} src={bitz} />
              </a>
            </div>
            <div>
              <ul>
                <li style={{ fontSize: "15px" }}>
                  {" "}
                  <a
                    href="/"
                    style={{ textDecoration: "none", color: "#168E91" }}
                  >
                    PMS
                  </a>
                </li>
                <li style={{ fontSize: "15px" }}>
                  {" "}
                  <a
                    href="/"
                    style={{ textDecoration: "none", color: "#168E91" }}
                  >
                    Staking
                  </a>
                </li>
                <li style={{ fontSize: "15px" }}>
                  {" "}
                  <a
                    href="/faq"
                    style={{ textDecoration: "none", color: "#168E91" }}
                  >
                    ETF
                  </a>
                </li>
                <li style={{ fontSize: "15px" }}>
                  {" "}
                  <a
                    href="/"
                    style={{ textDecoration: "none", color: "#168E91" }}
                  >
                    Bitz Club
                  </a>
                </li>
                <li style={{ fontSize: "15px" }}>
                  {" "}
                  <a
                    href="/"
                    style={{ textDecoration: "none", color: "#168E91" }}
                  >
                    Gallery
                  </a>
                </li>

                <li style={{ fontSize: "15px" }}>
                  {" "}
                  <a
                    href="/faq"
                    style={{ textDecoration: "none", color: "#168E91" }}
                  >
                    FAQ
                  </a>
                </li>
                <li style={{ fontSize: "15px" }}>
                  {" "}
                  <a
                    href="/"
                    style={{ textDecoration: "none", color: "#168E91" }}
                  >
                    Academy
                  </a>
                </li>
                <li style={{ fontSize: "15px" }}>
                  {" "}
                  <a
                    href="/"
                    style={{ textDecoration: "none", color: "#168E91" }}
                  >
                    News
                  </a>
                </li>

                <li>
                  {" "}
                  {isLoggedIn ? (
                    <button onClick={handleClickOpenLogin} style={buttonStyle}>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <img
                          width={20}
                          src={users}
                          alt="User Icon"
                          style={{ marginRight: "8px" }}
                        />
                        LOG IN
                      </span>
                    </button>
                  ) : (
                    <>
                      {isAdmin ? (
                        <Link
                          to="/admin/dashboard/business"
                          className="nav-link loginBtn"
                          style={{
                            color: "#ff355ba8f",
                            textDecoration: "none",
                            border: "1px #168E91 solid",
                            borderRadius: "8px",
                          }}
                        >
                          Dashboard
                        </Link>
                      ) : (
                        <Link
                          to="/user/dashboard"
                          className="nav-link loginBtn"
                          style={{
                            color: "#168E91",
                            textDecoration: "none",
                            border: "1px #168E91 solid",
                            borderRadius: "8px",
                          }}
                        >
                          Dashboard
                        </Link>
                      )}
                    </>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}

      <Menu
        className="menuPopup"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {" "}
        <NavLink to="/" style={{ color: "#fff", textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>PMS</MenuItem>
        </NavLink>
        <NavLink to="/" style={{ color: "#fff", textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>Staking</MenuItem>
        </NavLink>
        <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>ETF</MenuItem>
        </NavLink>
        <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>Bitz Club</MenuItem>
        </NavLink>
        <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>Gallery</MenuItem>
        </NavLink>
        <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>FAQ</MenuItem>
        </NavLink>
        <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>Academy</MenuItem>
        </NavLink>
        <NavLink to="" style={{ color: "#fff", textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>News</MenuItem>
        </NavLink>
        <li>
          {" "}
          {isLoggedIn ? (
            <li
              style={{ marginLeft: "15px", cursor: "pointer" }}
              onClick={handleClickOpenLogin}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                LOG IN
              </span>
            </li>
          ) : (
            <>
              {isAdmin ? (
                <Link
                  to="/admin/dashboard/business"
                  className="nav-link loginBtn"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    border: "1px #fff solid",
                    borderRadius: "8px",
                  }}
                >
                  Dashboard
                </Link>
              ) : (
                <Link
                  to="/user/dashboard"
                  className="nav-link loginBtn"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    border: "1px #fff solid",
                    borderRadius: "8px",
                  }}
                >
                  Dashboard
                </Link>
              )}
            </>
          )}
        </li>{" "}
      </Menu>
      <Dialog
        className="overFlowModal"
        sx={{ overflow: "auto" }}
        fullScreen={fullScreen}
        open={openRegister}
        // onClose={handleCloseRegister}
      >
        <Register
          setEmailMessage={setEmailMessage}
          message={handleClickOpenMessage}
          handleCloseRegister={handleCloseRegister}
          handleClickOpenLogin={handleClickOpenLogin}
        />
      </Dialog>

      <Dialog
        className="overFlowModal"
        fullScreen={fullScreen}
        open={openLogin}
        onClose={handleCloseLogin}
      >
        <MainSection
          handleCloseLogin={handleCloseLogin}
          cancel={handleCloseLogin}
          handleClickOpenRegister={handleClickOpenRegister}
        />
      </Dialog>

      <Dialog
        className="overFlowModalemail"
        fullScreen={fullScreen}
        open={openMessage}
        onClose={handleCloseMessage}
      >
        <Emailmessage
          handleClickOpenLogin={handleClickOpenLogin}
          close={handleCloseMessage}
          emailMessage={emailMessage}
        />
      </Dialog>
    </div>
  );
};

export default Header;
