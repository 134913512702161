import React from "react";
import { RHFSelect } from "src/components/hook-form";
import { countries } from "src/_mock";
import useLocales from "src/hooks/useLocales";


const Countries = () =>{
  const { translate } = useLocales();
  return(
    <RHFSelect name="country" label={translate("userProfile.countryS")} >
      <option />
      {countries.map(({ code, label }) => (
        <option value={code}>{label}</option>
      ))}
    </RHFSelect>
  );
  
} 
export default Countries;
