import { lazy } from "react";
import { Navigate } from "react-router";
import AuthGuard, { SubAdminGuard } from "src/guards/AuthGuard";
import UserLayout from "src/layouts/user";
import Loadable from "../Loadable";
import businessBuilder from "./businessBuilder";
import financial from "./financial";
import genealogy from "./genealogy";
import helpCenter from "./helpCenter";
import profile from "./profile";
import reports from "./reports";
import subscriptions from "./subscriptions";
import { element } from "prop-types";

const ProductList = Loadable(
  lazy(() =>
    import(
      "src/pages/userSide/onlineStore/productSubscription/productList/index"
    )
  )
);
const ProductDetails = Loadable(
  lazy(() =>
    import("src/pages/userSide/onlineStore/productSubscription/details/index")
  )
);
const Checkout = Loadable(
  lazy(() =>
    import("src/pages/userSide/onlineStore/productSubscription/checkout/index")
  )
);
const MyOrders = Loadable(
  lazy(() => import("src/pages/userSide/onlineStore/myOrders/index"))
);

const InvoiceDetails = Loadable(
  lazy(() => import("src/pages/dashboard/InvoiceDetails"))
);

const BlogPosts = Loadable(
  lazy(() => import("src/pages/userSide/blogs/BlogPosts"))
);
const BlogPost = Loadable(
  lazy(() => import("src/pages/userSide/blogs/BlogPost"))
);

const Dashboard = Loadable(
  lazy(() => import(`src/pages/userSide/dashboard/index`))
);

const MissedPoints = Loadable(
  lazy(() => import("src/pages/userSide/missedPoints"))
);

const Events = Loadable(lazy(() => import("src/pages/userSide/events/index")));
const PoolUpgrade = Loadable(
  lazy(() => import("src/pages/userSide/onlineStore/poolupgrade"))
);

const Voucher = Loadable(
  lazy(() => import("src/pages/userSide/voucher/index"))
);

const Invest = Loadable(lazy(() => import("src/pages/userSide/invest/index")));
const Invoice = Loadable(
  lazy(() => import("src/pages/userSide/invest/invoice/index"))
);
const VoucherInvoice = Loadable(
  lazy(() => import("src/pages/userSide/giftCode/invoice/index"))
);
const GiftCode = Loadable(
  lazy(() => import("src/pages/userSide/giftCode/index"))
);
const Investor = Loadable(
  lazy(() => import("src/pages/userSide/investor/index"))
);
const PendingPayments = Loadable(
  lazy(() => import("src/pages/userSide/pendingPayments/index"))
);

const Reward = Loadable(
  lazy(() => import("src/pages/userSide/bitzstoxReward/index"))
);
const Team = Loadable(lazy(() => import("src/pages/userSide/TeamNew/index")));

const user = [
  {
    path: "user",
    element: (
      <AuthGuard>
        <SubAdminGuard>
          <UserLayout />
        </SubAdminGuard>
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="dashboard" />, index: true },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "events",
        element: <Events />,
      },
      { path: "investor", element: <Investor /> },
      { path: "reward", element: <Reward /> },
      { path: "team", element: <Team /> },

      {
        path: "invest",
        children: [
          { index: true, element: <Navigate to="stake" /> },
          { path: "stake", element: <Invest /> },
          { path: "gift-code", element: <GiftCode /> },
          {
            path: ":id",
            element: <Invoice />,
          },
          {
            path: "pending_payments",
            element: <PendingPayments />,
          },
          {
            path: "my-orders",
            children: [
              { index: true, element: <MyOrders /> },
              {
                path: ":id",
                element: <VoucherInvoice />,
              },
            ],
          },
        ],
      },
      {
        path: "online-store",
        children: [
          {
            path: "product-subscription",
            children: [
              { index: true, element: <ProductList /> },
              {
                path: "checkout",
                element: <Checkout />,
              },
              { path: ":name", element: <ProductDetails /> },
            ],
          },
          {
            path: "poolupgrade",
            element: <PoolUpgrade />,
          },
          {
            path: "my-orders",
            children: [
              { index: true, element: <MyOrders /> },
              {
                path: ":id",
                element: <InvoiceDetails />,
              },
            ],
          },
        ],
      },
      {
        path: "blogs",
        children: [
          { index: true, element: <BlogPosts /> },
          { path: ":id", element: <BlogPost /> },
        ],
      },

      { path: "missed-points", element: <MissedPoints /> },
      { path: "voucher", element: <Voucher /> },
      { ...businessBuilder },
      { ...genealogy },
      { ...subscriptions },
      { ...financial },
      { ...helpCenter },
      { ...profile },
      { ...reports },
    ],
  },
];

export default user;
