import { useTheme } from "@emotion/react";
import {
  Box,
  Card,
  Container,
  Dialog,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import Iconify from "src/components/Iconify";
import Image from "src/components/Image";
import Logo from "src/components/Logo";
import Page from "src/components/Page";
import useResponsive from "src/hooks/useResponsive";
import LoginBanner from "src/images/login.webp";
import { PATH_AUTH } from "src/routes/paths";
import { RegisterForm } from "src/sections/auth/register";
import Emailmessage from "src/sections/auth/register/emailMessage";
import RootStyle from "./shared/rootStyle";
import "./style.css";
import { capitalCase } from "change-case";
import Scrollbar from "src/components/Scrollbar";

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 564,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: "100%",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(4, 0, 2, 0),
  width: { md: "700px", sm: "80%", xs: "96%" },
}));

export default function Register({
  setEmailMessage,
  message,
  handleCloseRegister,
  handleClickOpenLogin,
}) {
  const { uname } = useParams();

  return (
    <Page title="Register">
      <RootStyle sx={{ overflow: "auto" }}>
        {/* <Tooltip title="Close">
          <IconButton
            aria-label="close"
            onClick={handleCloseRegister}
            sx={{
              position: "absolute",
              backgroundColor: "#168E91 !important",
              borderRadius: "50%",
              top: "-30px",
              boxShadow: "2px 7px 10px 1px rgb(3 3 3 / 28%)",
              color: "white",
              right: { md: "-10px", sm: "10px", xs: "10px" },
              top: { md: "-20px", sm: "10px", xs: "10px" },
            }}
          >
            <Iconify icon="material-symbols:close" />
          </IconButton>
        </Tooltip> */}

        <Container>
          <ContentStyle>
            <Box
              sx={{
                flexGrow: 1,
                mb: 2,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography variant="h4" gutterBottom>
                {uname === "customer" ||
                uname === "partner" ||
                uname === "agency_partner" ||
                uname === "executive_partner" ||
                uname === "sales_manager" ||
                uname === "regional_manager" ||
                uname === "general_manager" ? (
                  <>
                    Join Us and Increase Your Income as a{" "}
                    {capitalCase(uname || "")}
                  </>
                ) : (
                  "Join Us and Increase Your Income"
                )}
              </Typography>
            </Box>

            <RegisterForm
              setEmailMessage={setEmailMessage}
              message={message}
              handleCloseRegister={handleCloseRegister}
              handleClickOpenLogin={handleClickOpenLogin}
            />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
