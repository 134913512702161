import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { capitalCase } from "change-case";
import moment from "moment";
import EmptyContent from "src/components/EmptyContent";
import Scrollbar from "src/components/Scrollbar";
import PaginationButtons from "src/components/pagination";
import ParseDate from "src/components/parse-date";
import useLocales from "src/hooks/useLocales";
import useEwallet from "../hooks/useEwallet";
import Filter from "./filterCard";
const DataList = () => {
  const { translate } = useLocales();
  const { data, fetchData, rowStart, setFilter, ...rest } = useEwallet();

  return (
    <>
      <Card sx={{ mt: 1, p: 2 }}>
        <Filter setFilter={setFilter} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translate("userFinancial.eWallet.no")}</TableCell>

                  <TableCell>
                    {translate("userFinancial.eWallet.username")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.usdt")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.sourceType")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.date")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {data?.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <EmptyContent title="No Data Available" />
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <TableBody>
                {data?.map((item, i) => (
                  <TableRow>
                    <TableCell>{i + rowStart}</TableCell>

                    <TableCell>
                      {item?.from_user?.prior_username
                        ? item?.from_user?.prior_username
                        : item?.from_user?.username}
                    </TableCell>
                    <TableCell>{item.payable_amount}</TableCell>
                    <TableCell> {capitalCase(item.payment_type)} </TableCell>
                    <TableCell>
                      <ParseDate>{item.created_at}</ParseDate>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      <PaginationButtons {...rest} />
    </>
  );
};

export default DataList;
