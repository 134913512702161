import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EmptyContent from "src/components/EmptyContent";
import PaginationButtons from "src/components/pagination";
import ParseDate from "src/components/parse-date";
import Scrollbar from "src/components/Scrollbar";
import useLocales from "src/hooks/useLocales";

const DataList = ({ data, rowStart, ...rest }) => {
  const { translate } = useLocales();
  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate("userFinancial.fundTransfer.no")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.fundTransfer.accountId")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.fundTransfer.paymentType")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.fundTransfer.amount")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.fundTransfer.date")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.fundTransfer.note")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.fundTransfer.paymentStatus")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {data?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <EmptyContent title="No Data Available" />
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <TableBody>
                {data.map(
                  (
                    {
                      user,

                      payment_type,

                      created_at,
                      note,
                      payment_status,
                      total_amount,
                    },
                    i
                  ) => {
                    return (
                      <TableRow>
                        <TableCell>{i + rowStart}</TableCell>
                        <TableCell>{user?.username}</TableCell>
                        <TableCell>{payment_type}</TableCell>
                        <TableCell>{total_amount}</TableCell>
                        <TableCell>
                          <ParseDate>{created_at}</ParseDate>
                        </TableCell>
                        <TableCell>{note}</TableCell>
                        <TableCell>{payment_status}</TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>

      <PaginationButtons {...rest} />
    </>
  );
};

export default DataList;
