import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const useGoogleOAuth = () => {
  const navigate = useNavigate();

  const handleGLoginSuccess = async (codeResponse) => {
    navigate(`/OAuth/g/${codeResponse.access_token}`);
  };

  return useGoogleLogin({
    onSuccess: handleGLoginSuccess,
    onError: (error) => console.log("Login Failed:", error),
  });
};

export default useGoogleOAuth;
