import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const NetworkMembers = Loadable(
  lazy(() => import("src/pages/members/network"))
);

const Investors = Loadable(lazy(() => import("src/pages/members/network")));
const Agency = Loadable(lazy(() => import("src/pages/members/network")));
const MemberProfile = Loadable(lazy(() => import("src/pages/members/Profile")));

const ExecutivePartner = Loadable(
  lazy(() => import("src/pages/members/network"))
);
const SalesManager = Loadable(lazy(() => import("src/pages/members/network")));
const RegionalManager = Loadable(
  lazy(() => import("src/pages/members/network"))
);
const GeneralManager = Loadable(
  lazy(() => import("src/pages/members/network"))
);
const SeniorPartner = Loadable(lazy(() => import("src/pages/members/network")));

const members = [
  {
    path: "members",
    children: [
      { element: <Navigate to="network" />, index: true },
      {
        path: "network",
        element: <NetworkMembers type={"partner"} label={"Partners"} />,
      },
      {
        path: "investor",
        element: <Investors type={"investor"} label={"Customers"} />,
      },

      {
        path: "seniorPartner",
        element: (
          <SeniorPartner type={"senior_partner"} label={"Senior Partners"} />
        ),
      },

      {
        path: "agency",
        element: <Agency type={"agency_partner"} label={"Agency Partners"} />,
      },

      {
        path: "executive_partner",
        element: (
          <ExecutivePartner
            type={"executive_partner"}
            label={"Executive Partners"}
          />
        ),
      },

      {
        path: "sales_manager",
        element: (
          <SalesManager type={"sales_manager"} label={"Sales Managers"} />
        ),
      },
      {
        path: "regional_manager",
        element: (
          <RegionalManager
            type={"regional_manager"}
            label={"Regional Managers"}
          />
        ),
      },
      {
        path: "general_manager",
        element: (
          <GeneralManager type={"general_manager"} label={"General Managers"} />
        ),
      },

      // {
      //   path: "holding-tank",
      //   element: <HoldingTank />,
      // },
      { path: "profile/:mid", element: <MemberProfile /> },
    ],
  },
];

export default members;
