import { lazy } from "react";
import { Navigate } from "react-router";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
import MainLayout from "../layouts/main";
import Loadable from "./Loadable";
import LandingPage from "src/components/root_page/index";
import OurTeam from "src/components/root_page/pages/ourteam";
import Exchange from "src/components/root_page/pages/exchange";
import Elotto from "src/components/root_page/pages/elotto";
import Eauction from "src/components/root_page/pages/eauction";
import Ecomm from "src/components/root_page/pages/ecomm";
import Ep2p from "src/components/root_page/pages/ep2p";
import NewsFeed from "src/components/root_page/pages/newsfeed";
import Faq from "src/components/root_page/pages/faq";
import GiftCode from "src/components/root_page/pages/giftCode/index";

// const HomePage = Loadable(lazy(() => import("src/pages/Home")));
const About = Loadable(lazy(() => import("src/pages/About")));
const Contact = Loadable(lazy(() => import("src/pages/Contact")));
const ComingSoon = Loadable(lazy(() => import("src/pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("src/pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("src/pages/Pricing")));
const Payment = Loadable(lazy(() => import("src/pages/Payment")));
const Page500 = Loadable(lazy(() => import("src/pages/Page500")));
const NotFound = Loadable(lazy(() => import("src/pages/Page404")));
const VerifiedUser = Loadable(
  lazy(() => import("src/pages/auth/VerifiedUser"))
);

const main = [
  {
    path: "/",
    element: <LandingPage />,

    children: [
      { element: <Navigate to="/" />, index: true },
      { path: "about-us", element: <About /> },
      { path: "contact-us", element: <Contact /> },
      {
        path: ":uname",
        element: <LandingPage />,
      },
      // { path: "user-verify", element: <VerifiedUser /> },
    ],
  },
  { path: "user-verify", element: <VerifiedUser /> },
  {
    path: "our-team",
    element: <OurTeam />,
  },
  {
    path: "exchange",
    element: <Exchange />,
  },
  {
    path: "elotto",
    element: <Elotto />,
  },
  {
    path: "eauction",
    element: <Eauction />,
  },
  {
    path: "ecomm",
    element: <Ecomm />,
  },
  {
    path: "ep2p",
    element: <Ep2p />,
  },
  {
    path: "news-feed",
    element: <NewsFeed />,
  },
  {
    path: "gift-code",
    element: <GiftCode />,
  },
  {
    path: "faq",
    element: <Faq />,
  },
  {
    path: "*",
    element: <LogoOnlyLayout />,
    children: [
      { path: "coming-soon", element: <ComingSoon /> },
      { path: "maintenance", element: <Maintenance /> },
      { path: "pricing", element: <Pricing /> },
      { path: "payment", element: <Payment /> },
      { path: "500", element: <Page500 /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
];

export default main;
