import { Box, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import { PATH_DASHBOARD } from "src/routes/paths";
import DirectorThree from "./director-three";
import DirectorOne from "./directorOne";
import DirectorTwo from "./directorTwo";
import useLocales from "src/hooks/useLocales";

const Index = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const { translate } = useLocales();

  return (
    <div>
      <Page title={translate("directors")}>
        <Box sx={{ p: 2 }}>
          <HeaderBreadcrumbs
            heading={translate("directors")}
            links={[
              { name: "Dashboard", href: PATH_DASHBOARD.root },
              {
                name: translate("directors"),
              },
            ]}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Item>
                <DirectorOne />
              </Item>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Item>
                <DirectorTwo />
              </Item>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Item>
                <DirectorThree />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Page>
    </div>
  );
};

export default Index;
