import { lazy } from "react";
import GuestGuard from "src/guards/GuestGuard";
import Loadable from "./Loadable";
import { Navigate } from "react-router";

const Login = Loadable(lazy(() => import("src/pages/auth/Login")));
const Register = Loadable(lazy(() => import("src/pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("src/pages/auth/ResetPassword"))
);
const ResetAccountID = Loadable(
  lazy(() => import("src/pages/auth/ForgotAccountID"))
);
const ResendVerifyAccount = Loadable(
  lazy(() => import("src/pages/auth/ResendVerifyAccount"))
);

const VerifyCode = Loadable(lazy(() => import("src/pages/auth/VerifyCode")));
const NewPassword = Loadable(
  lazy(() => import("src/pages/auth/NewPassword/index"))
);
const ChangedPassword = Loadable(
  lazy(() => import("src/pages/auth/ChangedPassword"))
);
const CoinPayment = Loadable(lazy(() => import("src/pages/auth/payment")));
const Emailmessage = Loadable(
  lazy(() => import("src/sections/auth/register/emailMessage.js"))
);
const auth = [
  {
    path: "auth",
    element: <GuestGuard />,
    children: [
      {
        path: "login",
        element: <Navigate to="/" />,
      },
      {
        path: "coinpayment",
        element: <CoinPayment />,
      },
      {
        path: "register",
        element: <Register />,
      },
      { path: "emailmessage", element: <Emailmessage /> },
      // { path: "login-unprotected", element: <Login /> },
      { path: "register-unprotected", element: <Register /> },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "reset-accountid", element: <ResetAccountID /> },
      { path: "resend-verifyaccount", element: <ResendVerifyAccount /> },
      { path: "verify", element: <VerifyCode /> },
      { path: "new-password/", element: <NewPassword /> },
      { path: "password-reset/success", element: <ChangedPassword /> },
      {
        path: ":uname",
        element: <Register />,
      },
    ],
  },
];

export default auth;
