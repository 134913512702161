import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const IncomeReport = Loadable(
  lazy(() => import("src/pages/userSide/incomeReport/index"))
);

const Referral = Loadable(
  lazy(() => import("src/pages/userSide/referralReport/index"))
);
const WithdrawalReport = Loadable(
  lazy(() => import("src/pages/userSide/withdrawalReport/index"))
);

const reports = {
  path: "reports",
  children: [
    { index: true, element: <Navigate to="e-wallet" /> },
    { path: "incomeReport", element: <IncomeReport /> },
    { path: "referralReport", element: <Referral /> },
    { path: "withdrawalReport", element: <WithdrawalReport /> },
  ],
};

export default reports;
