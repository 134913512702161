import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import Iconify from "src/components/Iconify";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import axiosInstance from "src/utils/axios";
import useDirectorsDetailsTwo from "./hooks/useDirectorsDetailsTwo";

const getVerification = async () => {
  try {
    const { status, data } = await axiosInstance(
      "api/admin/director-verification"
    );
    if (status === 200) {
      return data.verification_type;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const DirectorTwo = () => {
  const { methods, detailId } = useDirectorsDetailsTwo();
  const { enqueueSnackbar } = useSnackbar();
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [verificationType, SetverificationType] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const onSubmit = async (reqdata) => {
    setEmail(reqdata.email);
    setPassword(reqdata.password);
    setUsername(reqdata.username);
    let verificationType = await getVerification();
    SetverificationType(verificationType);
    if (verificationType) {
      setOpenQrDialog(true);
    }
  };

  const [otpError, setOtpError] = useState("");
  const clearErrors = () => {
    setOtpError("");
  };

  const handleCloseDialog = () => {
    setOpenQrDialog(false);
    clearErrors();
  };

  const update = async () => {
    if (true) {
      let data = new FormData();
      data.append("email", email);
      data.append("password", password);
      data.append("username", username);
      data.append("otp", qrCode);
      data.append("id", detailId);
      data.append("_method", "PUT");
      data.append("verification_type", verificationType);

      try {
        const { status, data: responseData } = await axiosInstance({
          method: "post",
          data: data,
          url: "api/admin/director-two",
        });
        if (status && responseData.status) {
          enqueueSnackbar(responseData.message);
          setOpenQrDialog(false);
          setQrCode("");
          handleCloseDialog();
          clearErrors();
        } else {
          enqueueSnackbar(responseData.message, { variant: "error" });
        }
      } catch (err) {
        setOtpError(err.message);
        Object.entries(err.errors).forEach(([k, v]) => {
          setOtpError(v?.flat()?.find(Boolean));
        });
        console.error(err);
      }
    }
  };

  return (
    <div>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Stack spacing={3} alignItems="flex-end">
              <RHFTextField
                name="email"
                type="text"
                label="eMail ID"
                autoComplete="off"
              />
              {/* <RHFTextField name="username" type="text" label="Username" /> */}
              <RHFTextField
                name="password"
                label="Passcode *"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <RHFTextField
                name="repassword"
                label="Confirm Passcode *"
                type={showRePassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowRePassword(!showRePassword)}
                      >
                        <Iconify
                          icon={
                            showRePassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </FormProvider>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={openQrDialog}
        onClose={() => setOpenQrDialog(false)}
      >
        <DialogContent>
          <Box sx={{ textAlign: "right" }}>
            <Tooltip title="close">
              <IconButton onClick={() => setOpenQrDialog(false)}>
                <Iconify icon="material-symbols:close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            {verificationType == "google2fa"
              ? "Two-Factor Authentication (2FA)"
              : "We have sent you an eMail ID with OTP, please check the OTP for eMail ID verification"}
          </Typography>
          <Stack spacing={3}>
            <TextField
              value={qrCode}
              onChange={(e) => setQrCode(e.target.value)}
              placeholder="Enter OTP"
              error={Boolean(otpError)}
              helperText={otpError}
            />
            <Button variant="contained" onClick={update}>
              Submit
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DirectorTwo;
