import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import Users from "src/components/all-users";
import { FormProvider, RHFSelect } from "src/components/hook-form";
import EWalletPaymentTypes from "src/components/payment-types/e-wallet";
import useLocales from "src/hooks/useLocales";
import { date, object, ref } from "yup";
import CustomRHFDatePicker from "./customRHFDatePicker";
import { LoadingButton } from "@mui/lab";
import { defaultReportFilter } from "../../hooks/use-filter";
import { useEffect } from "react";

export const incomeReportDefaultValues = {
  start_date: null,
  end_date: null,
  payment_type: "all",
  plan: "",
};

const getTomorrow = () => {
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow;
};

const schema = object().shape({
  start_date: date()
    .max(getTomorrow(), "Future start date not allowed")
    .nullable(),
  end_date: date()
    .min(ref("start_date"), "end date can't be before start date")
    .nullable(),
});

const Filter = ({ methods, onFilter }) => {
  const { translate } = useLocales();
  const {
    formState: { isSubmitting },
  } = methods;
  const reset = () => methods.reset(defaultReportFilter);
  useEffect(() => {
    return () => reset();
  }, []);
  return (
    <FormProvider methods={methods} onSubmit={onFilter}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1,1fr)",
            sm: "repeat(4, 1fr)",
          },
          rowGap: 3,
          columnGap: 3,
          marginBottom: "1rem",
        }}
      >
        <CustomRHFDatePicker
          label={translate("adminFinancial.payout.pickStartDate")}
          name="start_date"
          size="small"
        />
        <CustomRHFDatePicker
          label={translate("adminFinancial.payout.pickEndDate")}
          name="end_date"
          size="small"
        />
        <Users
          label="Partner ID"
          name="user_id"
          size="small"
          type="partner"
          report="admin"
        />

        <Box sx={{ justifyContent: "flex-end", display: "flex", mb: 1 }}>
          {" "}
          <LoadingButton
            loading={isSubmitting}
            size="small"
            variant="contained"
            type="submit"
            sx={{ height: "40px" }}
          >
            Get Report{" "}
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default Filter;
